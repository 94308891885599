/* can't get the following to work for local fonts:
@font-face {
  font-family: 'Public Sans';
  src: url('Public_Sans/PublicSans-VariableFont_wght.ttf') format('truetype'),
       url('Public_Sans/scss/PublicSans-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 1000;
}
*/
@font-face {
  font-family: 'Public Sans';
  src: url('https://fonts.gstatic.com/s/publicsans/v15/ijwRs572Xtc6ZYQws9YVwkNBdp_yw_k0.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
  font-weight: 100 1000;
}

#portal-logo img {
    filter: invert(60%) sepia(75%) saturate(503%) hue-rotate(62deg) brightness(93%) contrast(91%);
}
a {
  text-decoration: none;
}
:root{
    --bs-font-sans-serif: "Public Sans", Helvetica, sans-serif;
    --bs-font-serif:  "Public Sans", Helvetica, sans-serif;
    --bs-body-font-size: 16px;
  }

  body.mosaic-layout-customized, body.mosaic-enabled {
    #call-to-action .aspect-ratio-box-inside {
      max-width: 100%;
      max-height: 100%;
      a {
        color: white !important;
      }
    }
  }
  
  button:focus:not(:focus-visible){

  }
  // fix the folder content z-index issue
  body:not(.backend) .outer-wrapper {
    position: relative;
    z-index: 1;
  }
  
  div.riform {
    z-index: 99;
  }
  // FD9683 remove upload button from folder contents view
  
  .template-folder_contents .pat-structure {
    a#btn-upload {
      display: none;
    }
    a#btn-rearrange.btn {
      border-right: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 15px;
    }
  }

// FROM LAYOUT.LESS 
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  $viewportHeight: 100vh;
  $heroHeight: 55vh;
  $viewportWidthMin: 900px;
  $navbarHeight: 115px;
  $bannerHeight: 140px;
  $rightnavWidth: 250px;
  $divider-color-on-darkbg: #ffffff;
  $divider-line-width: 2px;
  $font-size-main-h1: 1.32em;
  $font-size-main-p: 1em;
  $font-weight-skinny: 100;
  $font-weight-normal: 500; // medium
  $font-weight-bold:   700;
  $font-weight-extrabold: 800;
  $navbar-mini-width: 100%;
  $banner-color: #fff;

  $divider-color-on-lightbg: hsl(120, 0.7%, 70.8%);
  $color-white-text: hsl(0, 0%, 94%);
  $color-white-text-hover: hsl(0, 0%, 97%);
  $color-background-call-to-action: hsla(0, 0%, 0%, 0);
  $color-background-call-to-action-hover: hsla(0, 0%, 100%, 0.50);
  $color-blue: hsl(193.8, 48.5%, 47.3%);
  $color-gray: hsl(120, 0.7%, 70.8%);
  $color-brown: #474135;
  $color-brown-dark: lighter($color-brown, 30%);
  $color-brown-light:lighten($color-brown, 10%);
  $cabq-black: #212427; //2024 black
  $cabq-dark-blue:    #01636A; //2024 turquois
  $cabq-orange1:      #F59F1F; // 2024 orange

* {
  box-sizing: border-box;
}
/* LINK FORMATTING */

.visually-hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

#footer {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
@media (min-width: 1200px) {
  body:not(.interior){ // interior pages don't have quicklinks anymore
  #footer, .mosaic-cabq\.sectionhighlight-tile {
    width: calc(100% - $rightnavWidth);
  }
}
}
@media (min-width: 900px) and (max-width: 1199px) {
  #footer {
    width: 100vw;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #footer {
    width: 100vw;
  }
}
@media (max-width: 599px) {
  #footer {
    width: 100vw;
  }
}
a.siteSearchButton.nav-link {
  font-size: 1.2em;
  padding-top: 10px;
}
#footer .footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  &.back-to-top {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  #footer .footer-row {
    flex-flow: row nowrap;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #footer .footer-row {
    flex-flow: row nowrap;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #footer .footer-row {
    flex-flow: row nowrap;
  }
}
@media (max-width: 599px) {
  #footer .footer-row {
    flex-flow: column nowrap;
  }
}
#footer .footer-row hr.footer-item {
  margin-block-start: 0;
  margin-block-end: 0;
  border: 0;
  height: $divider-line-width;
  background-image: linear-gradient(to right, $color-gray, $color-gray);
}
@media (min-width: 1200px) {
  #footer .footer-row hr.footer-item {
    margin-left: calc(0px - 1em);
    width: calc(100% + 1em);
    overflow: visible;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #footer .footer-row hr.footer-item {
    margin-left: calc(0px - 1em);
    width: calc(100% + 1em);
    overflow: visible;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #footer .footer-row hr.footer-item {
    margin-left: calc(0px - 1em);
    width: calc(100% + 1em);
    overflow: visible;
  }
}
@media (max-width: 599px) {
  #footer .footer-row hr.footer-item {
    margin-left: 0px;
    width: 100%;
    overflow: visible;
  }
}
.footer-row.back-to-top {
  flex-flow: row nowrap;
  font-size: 2em;
  align-items: flex-end;
}

#footer .footer-row div.footer-item {
  flex: 0 1 25%;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 1200px) {
  #footer .footer-row div.footer-item h2 {
    text-transform: uppercase;
    font-size: 1.32vw;
    margin-block-end: 0.5em;
  }
  #footer .footer-row div.footer-item p {
    font-size: 1em;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #footer .footer-row div.footer-item h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  #footer .footer-row div.footer-item p {
    font-size: 1em;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #footer .footer-row div.footer-item h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  #footer .footer-row div.footer-item p {
    font-size: 1em;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (max-width: 599px) {
  #footer .footer-row div.footer-item h2 {
    text-transform: uppercase;
    font-size: 4vw;
    margin-block-end: 0.5em;
  }
  #footer .footer-row div.footer-item p {
    font-size: 1em;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
#footer .footer-row div.footer-subitem {
  padding:0;
}
@media (min-width: 1200px) {
  #footer .footer-row div.footer-subitem {
    min-height: 250px;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #footer .footer-row div.footer-subitem {
    min-height: 250px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #footer .footer-row div.footer-subitem {
    min-height: 250px;
  }
}
ul.foot-socialmedia {
  margin: 0px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-left: 0px;
  list-style-type: none;
}
#footer .footer-row div.footer-subitem + div.footer-subitem {
  border-top: $divider-line-width solid $divider-color-on-lightbg;
}

@media only screen and (min-width: 1200px) {
  #herospacer {
    display: block;
  }
  #mainright {
    display: block;
    width: $rightnavWidth;
  }

}
@media only screen and (max-width:1199px){
  #call-to-action 
  { 
    .aspect-ratio-box-inside {
      grid-template-columns: 1fr 1fr 1fr;
      height: calc(100vw * .30);
    }
  }
}
@media only screen and (max-width:599px){
  #call-to-action {
     .aspect-ratio-box-inside {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media only screen and (min-width:1200px){
  #call-to-action 
  { 
    .aspect-ratio-box-inside {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      height: 100px !important;
    }
  }

}

@media only screen and (max-width: 1199px) {
  #herospacer {
    display: none;
  }
  #mainleft {
    width: 100%;
  }

  #mainright {
    display: none;
    width: 0px;
  }

  #call-to-action 
  { 
    .aspect-ratio-box-inside {
      height: calc(100vw * .3);
      margin-top: 13px;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 1200px) {
  #hero {
    background-color: #9e72d2;
  }
}
@media only screen and (max-width: 1199px) {
  #hero {
    background-color: orange;
  }
}

#hero {
  display:flex;
  justify-content:space-around;
  height: 450px;
  background-image: url("/++theme++cabq-plone-6-theme/_assets/img/bg_hero.png");
  /* The image used */
  background-position: bottom left;
  /* You must set a specified height */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  .icon{
    width:100%;
  }
}
#hero > #cityname {
  color: white;
  position: absolute;
  float: left;
  font-weight: 100;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
  border-right: $divider-line-width solid $divider-color-on-darkbg;
}
@media (max-width: 599px) {
  #hero > #cityname {
    font-size: calc(12px + 3.3vw);
    margin: 2vw;
    padding: 2vw;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #hero > #cityname {
    margin: 1vw;
    padding: 1vw;
    font-size: 38px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #hero > #cityname {
    margin: 1vw;
    padding: 1vw;
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  #hero > #cityname {
    margin: 1vw;
    padding: 1vw;
    font-size: 38px;
  }
}
#hero > #focusnow {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 599px) {
  #hero > #focusnow .story a {
    text-decoration: none;
    color: $color-blue;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
  }
  #hero > #focusnow .story .icon > img {
    display: block;
    margin: auto;
    width:  185em;
  }
  #hero > #focusnow .story .texta > h1 {
    display: inline;
    font-size: 5vw;
  }
  #hero > #focusnow .story .texta > h1:after {
    content: " ";
    display: block;
    border-bottom: $divider-line-width solid $divider-color-on-lightbg;
  }
  #hero > #focusnow .story .texta > p {
    line-height: 120%;
    margin-top: 12px;
    font-size: .9em !important;
  }
  #hero > #focusnow .story .texta {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #hero > #focusnow .story a {
    text-decoration: none;
    color: $color-blue;
    background-color: rgba(255, 255, 255, 0);
  }
  #hero > #focusnow .story .icon > img {
    display: block;
    margin: auto;
    width: 185em;
  }
  #hero > #focusnow .story .texta > h1:after {
    content: " ";
    display: block;
    border-bottom: $divider-line-width solid $divider-color-on-lightbg;
  }
  #hero > #focusnow .story .texta > p {
    line-height: 120%;
    margin-top: 12px;
  }
  #hero > #focusnow .story a {
    display: flex;
    align-items: center;
  }

  #hero > #focusnow .story .texta > p {
    font-size: 17px;
    margin-left: 3px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #hero > #focusnow .story a {
    text-decoration: none;
    color: $color-blue;
    background-color: rgba(255, 255, 255, 0);
  }
  #hero > #focusnow .story .icon > img {
    display: block;
    margin: auto;
    width: 185px;
  }
  #hero > #focusnow .story .texta > h1:after {
    content: " ";
    display: block;
    border-bottom: $divider-line-width solid $divider-color-on-lightbg;
  }
  #hero > #focusnow .story .texta > p {
    line-height: 120%;
    margin-top: 12px;
  }
  #hero > #focusnow .story a {
    display: flex;
    align-items: center;
  }

  #hero > #focusnow .story .texta > p {
    font-size: 17px;
    margin-left: 3px;
  }
}
@media (min-width: 1200px) {
  #hero > #focusnow .story a {
    display: flex;
    text-decoration: none;
    color: $color-blue;
    background-color: rgba(255, 255, 255, 0);
    align-items: center;
  }
  #hero > #focusnow .story .icon > img {
    display: block;
    margin: auto;
    width: 185px;
    height: 185px;
  }
  #hero > #focusnow .story .texta > h1 {
    display: inline;
    font-size: 28px;
  }
  #hero > #focusnow .story .texta > h1:after {
    content: " ";
    display: block;
    border-bottom: $divider-line-width solid $divider-color-on-lightbg;
  }
  #hero > #focusnow .story .texta > p {
    line-height: 120%;
    margin-top: 12px;
  }
  #hero > #focusnow story a {
    display: flex;
    align-items: center;
  }
  #hero > #focusnow .story .texta > p {
    font-size: 17px;
    margin-left: 3px;
  }
}

@media (min-width: 1200px) {
  #navbar a {
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }

  #navbar > #navbar-mini {
    display: none;
  }
  form#mininav {
    padding-right: 15px;
}
#navbar > #navbar-large {
    #portal-globalnav{
      flex-direction: row;
      flex-wrap: nowrap;
    }
    div#navcall {
      align-self: flex-end;
    }
    z-index: 1;
    background: white;
    width: 100%;
    height: $navbarHeight;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-left: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    #navlogo {
      /* navbar: ABQ Logo */
      background-color: white;
      flex: 0 1 340px;
      display: flex;
      align-items: center;
      margin-left: 15px;
      margin-top: 30px;
      &>a {
        font-size: 2em;
        line-height: 1.2em;
        img {
          float: left;
          flex-shrink: 0;
          margin-left: 15px;
        }
      }
    }
    #navsect {
    /* navbar: sections */
      background-color: white;
      flex: 0 1 680px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top:10px;
      margin-right: 15px;
      .link-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 100%;
        &>ul {
          // display: flex;
          // flex-direction: row;
          align-items: center;
          align-self: flex-end;
          &+ ul {
            margin-top: 16px;
          }
         }
         li {
          padding: 0px 5px 0px 5px;
          list-style-type: none;
          white-space: nowrap;
          a{
            display: flex;
            align-items: center;
          }
        }
       }
     }
  }
}

@media (min-width: 900px) and (max-width: 1199px) {
  #navbar a {
    text-decoration: none;
  }

  #navbar > #navbar-mini {
    $navbar-mini-width: 320px;
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%
  }
  #navbar > #navbar-mini .menu-btn {
    position: fixed;
    right: 0;
    background-color: rgba(255,255,255,0);
    border: none;
    padding: 0px 20px 0px 0px;
    margin: 0;
    line-height: 50px;
    font-size: 100px;
    cursor: pointer;
    float: right;
    top: 0;
  }
  #navbar > #navbar-mini .menu-btn:focus {
    outline: 0;
  }
  #navbar > #navbar-mini .menu-icon {
    color: black;
  }
  #navbar > #navbar-mini .menu-icon:hover {
    background-color: none;
  }
  #navbar > #navbar-mini .menu-content {
    z-index: 2;
    display: none;
    overflow: auto;
    background-color: $cabq-tan-light;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  #navbar > #navbar-mini ul.show {
    /* class="show" toggled by Javascript */
    display: block;
  }
  #navbar > #navbar-mini ul {
    margin: 0px;
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom: 15px;
    list-style: none;
    overflow: hidden;
  }
  #navbar > #navbar-mini li {
    padding: 4px 0px 3px 0px;
    font-size: 1em;
    text-align:left;
  }
  #navbar > #navbar-mini li.menu-item a {
    display: inline-block;
  }
  #navbar > #navbar-mini li.menu-item a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini span.search-icon {
    width: 85%;
    display: flex;
  }

  #navbar > #navbar-mini li.menu-item-multianchor a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini li.menu-separator {
    height: 0px;
    width: calc($navbar-mini-width - 40px);
    border-top: 1px dashed #cec6b1;
  }
  #navbar > #navbar-mini li:focus {
    border-right: 4px solid #227ae6;
    background-color: #f1f1f1;
  }

  #navbar > #navbar-large {
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #navbar a {
    text-decoration: none;
  }

  #navbar > #navbar-mini {
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    min-width: $navbar-mini-width;
  }
  #navbar > #navbar-mini .menu-btn {
    position: fixed;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 0px 20px 0px 0px;
    margin: 0;
    line-height: 50px;
    font-size: 100px;
    cursor: pointer;
    float: right;
    top: 0;
  }
  #navbar > #navbar-mini .menu-btn:focus {
    outline: 0;
  }
  #navbar > #navbar-mini .menu-icon {
    color: $cabq-black;
  }
  #navbar > #navbar-mini .menu-icon:hover {
    background-color: none;
  }
  #navbar > #navbar-mini .menu-content {
    z-index: 2;
    display: none;
    overflow: auto;
    background-color: $cabq-tan-light;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  #navbar > #navbar-mini ul.show {
    /* class="show" toggled by Javascript */
    display: block;
  }
  #navbar > #navbar-mini ul {
    margin: 0px;
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom: 15px;
    list-style: none;
    overflow: hidden;
  }
  #navbar > #navbar-mini li {
    padding: 4px 0px 3px 0px;
    font-size: 1em;
    text-align:left;
  }
  #navbar > #navbar-mini li.menu-item a {
    display: inline-block;
  }
  #navbar > #navbar-mini li.menu-item a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini span.search-icon {
    width: 85%;
    display: flex;
  }

  #navbar > #navbar-mini li.menu-item-multianchor a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini li.menu-separator {
    height: 0px;
    width: calc($navbar-mini-width - 40px);
    border-top: 1px dashed #cec6b1;
  }
  #navbar > #navbar-mini li:focus {
    border-right: 4px solid #227ae6;
    background-color: #f1f1f1;
  }

  #navbar > #navbar-large {
    display: none;
  }
}
@media (max-width: 599px) {
  #navbar a {
    text-decoration: none;
  }

  #navbar > #navbar-mini {
    z-index: 1;
    position: fixed;
    top: 0;
    right: 0;
    min-width: $navbar-mini-width;
  }
  #navbar > #navbar-mini .menu-btn {
    position: fixed;
    right: 0;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 0px 0px 0px 0px;
    margin: 0;
    line-height: 50px;
    font-size: 100px;
    cursor: pointer;
    float: right;
    top:0;
  }
  #navbar > #navbar-mini .menu-btn:focus {
    outline: 0;
  }
  #navbar > #navbar-mini .menu-icon {
    color: black;
  }
  #navbar > #navbar-mini .menu-icon:hover {
    background-color: none;
  }
  #navbar > #navbar-mini .menu-content {
    z-index: 2;
    display: none;
    overflow: auto;
    background-color: $cabq-tan-light;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
  #navbar > #navbar-mini ul.show {
    /* class="show" toggled by Javascript */
    display: block;
  }
  #navbar > #navbar-mini ul {
    margin: 0px;
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom: 15px;
    list-style: none;
    overflow: hidden;
  }
  #navbar > #navbar-mini li {
    padding: 4px 0px 3px 0px;
    font-size: 1em;
    text-align:left;
  }
  #navbar > #navbar-mini li.menu-item a {
    display: inline-flex;
  }
  #navbar > #navbar-mini li.menu-item a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini span.search-icon {
    width: 85%;
    display: flex;
  }
  #navbar > #navbar-mini #mininav.navbar-form {
    padding: 0px 15px;
    margin-top: 8px;
    margin-right: 2em;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,0.1), 0 1px 0 rgba(255,255,255,0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1), 0 1px 0 rgba(255,255,255,0.1);
    flex-direction: row-reverse;
    display: inline-flex;
  }
  #navbar > #navbar-mini li.menu-item-multianchor a img {
    vertical-align: middle;
    display: inline;
  }
  #navbar > #navbar-mini li.menu-separator {
    height: 0px;
    width: calc($navbar-mini-width - 40px);
    border-top: 1px dashed #cec6b1;
  }
  #navbar > #navbar-mini li:focus {
    border-right: 4px solid #227ae6;
    background-color: #f1f1f1;
  }

  #navbar > #navbar-large {
    display: none;
  }
}
@media (min-width: 1200px) {
  #rightnav {
    width: $rightnavWidth;
    height: $viewportHeight;
    position: absolute;
    top: 656px;
    right: 0px;
    font-size: 1.3em;
    font-weight: $font-weight-bold;
    background-color: transparent;
    opacity: 0;
    /* make things invisible upon start */
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  #rightnav img {
    padding-right: 2px;
    padding-left: 2px;
  }
  #rightnav ul {
    margin: 0px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    a{
      &:hover,&:link,&:visited,&:focus,&active{
        color: $cabq-black;
      }
    }
  }
  #rightnav li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    height: calc((100vh - 130px) / 6.5);
    width: 200px;
    align-items: center;
    justify-content: center;
    border-top: $divider-line-width solid $divider-color-on-lightbg;
    background-color: white;
  }
  #rightnav li:last-child {
    border-bottom: $divider-line-width solid $divider-color-on-lightbg;
  }
  #rightnav.fix-right {
    display: flex;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #rightnav {
    display: none;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #rightnav {
    display: none;
  }
}
@media (max-width: 599px) {
  #rightnav {
    display: none;
  }
}
#banner {
  display: flex;
  /* ensure content is 75% height of banner, pad 12.5% all around top right bottom left */
  // padding: calc($bannerHeight * 0.1) 15px calc($bannerHeight * 0.1) 25px;
  // height: $bannerHeight;
  background-color: $banner-color;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto calc(0.75 * $bannerHeight);
  position:relative;
  #bannertitle{
    border-right: 2px #dedede solid;
  }
}
/*  @media (min-width: 1200px) {
  #banner {
    background-image: url("/++theme++cabq-plone-6-theme/_assets/img/bg_oneabq.svg");
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #banner {
    background-image: url("/++theme++cabq-plone-6-theme/_assets/img/bg_oneabq.svg");
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #banner {
    background-image: url("/++theme++cabq-plone-6-theme/_assets/img/bg_oneabq.svg");
  }
}
@media (max-width: 599px) {
  #banner {
    background-image: none;
  }
}
*/
#banner #bannercontent {
  position: absolute;
  top: 12em;
  left: 30px;
  padding: 15px;
  background: white;
  border-radius: 5px;
  font-weight: $font-weight-bold;
  color: $cabq-dark-blue;
  #banner-text-box{
    display: flex;
    align-items: center;
  }
  @media (max-width:899px){
    display: none;
  }
}
@media (min-width: 1200px) {
  #banner #bannertitle {
    text-transform: uppercase;
    font-size: 3em;
    padding-right: 45px;
    padding-left: 30px;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #banner #bannertitle {
    text-transform: uppercase;
    font-size: 2.7em;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #banner #bannertitle {
    text-transform: uppercase;
    font-size: 2.7em;
  }
}
@media (max-width: 599px) {
  #banner #bannertitle {
    font-size: calc(1.5em + 2vw);
  }
  #bannerImage img{
    display: none;
  }
}
#bannerImage {
  height: 66px;
  margin-left: 45px;
  margin-right: 45px;
  img{
  height:100%;
}
}
#banner #breadcrumb {
  font-size: 1em;
  padding-left: 0.2em;
}
#banner #breadcrumb a {
  text-decoration: none;
  color: white;
}
#banner #breadcrumb a:not(:last-child):after {
  content: "|";
  position: relative;
  padding-right: 4px;
  padding-left: 8px;
  color: $divider-color-on-darkbg;
}
body:not(.interior),body.interior.section-homepage{
div.sections > div.sec-row:nth-of-type(2n) {
  flex-direction: row-reverse;
  color: white;
  background-color: $color-brown;
}
div.sections > div.sec-row:nth-of-type(2n) div.texta {
  border-left: $divider-line-width solid $divider-color-on-darkbg;
}

div.sections > div.sec-row:nth-of-type(2n + 1) div.text-bloc > div.icon > img {
  filter: invert(50%) sepia(100%);
}
div.sections > div.sec-row:nth-of-type(2n + 1) div.texta {
  border-left: $divider-line-width solid $divider-color-on-lightbg;
}

.sections div.sec-row {
  display: flex;
  width: 100%;
}
.sections div.sec-cell {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1200px) {
  .sections div.sec-cell {
    width: 50%;
    max-height: 35vh;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .sections div.sec-cell {
    width: 50%;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .sections div.sec-cell {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .sections div.sec-cell {
    width: 100%;
  }
}
.sections div.text-bloc {
  padding: 5px;
}
.sections div.text-bloc > div.icon {
  width: 30%;
}
.sections div.text-bloc > div.icon img {
  width: 100%;
  height: auto;
}
.sections div.text-bloc > div.texta {
  width: 70%;
  padding-left: 1em;
  padding-right: 1.5em;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}
@media (min-width: 1200px) {
  .sections div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  .sections div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .sections div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  .sections div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
    margin-block-start: .9em;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .sections div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
    margin-block-start: .9em;
  }
  .sections div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (max-width: 599px) {
  .sections div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 4vw;
    margin-block-end: 0.5em;
    margin-block-start: .9em;
  }
  .sections div.text-bloc > div.texta p {
    font-size: 1em;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
  .sections div.text-bloc>div.texta{
    width: 100%;
  }
}
.sections div.image-bloc {
  padding: 0px;
}
@media (min-width: 1200px) {
  .sections div.image-bloc {
    display: block;
    overflow: hidden;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  .sections div.image-bloc {
    display: block;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .sections {
    div.text-bloc>div.texta{
      width: 100%;
    }
    div.icon {
    display: none;
  }
}
}
@media (max-width: 599px) {
  .sections div.icon {
    display: none;
  }
}
.sections div.image-bloc > img {
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
}// ! body not interior
/* :: links-page :: */
#links-page {
  display: block;
  width: 100%;
}
@media (min-width: 1200px) {
  #links-page div.image-banner {
    display: block;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #links-page div.image-banner {
    display: block;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #links-page div.image-banner {
    display: none;
  }
}
@media (max-width: 599px) {
  #links-page div.image-banner {
    display: none;
  }
}
#links-page div.image-banner > img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
#links-page div.sec-cell {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 1200px) {
  #links-page div.sec-cell {
    flex-direction: row;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #links-page div.sec-cell {
    flex-direction: row;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #links-page div.sec-cell {
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  #links-page div.sec-cell {
    flex-direction: column;
  }
}
#links-page div.text-bloc > div.linksa {
  min-width: 220px;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (min-width: 1200px) {
  #links-page div.text-bloc > div.linksa {
    margin-top: 50px;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #links-page div.text-bloc > div.linksa {
    margin-top: 50px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #links-page div.text-bloc > div.linksa {
    margin-top: 50px;
  }
}
@media (max-width: 599px) {
  #links-page div.text-bloc > div.linksa {
    margin-top: 0px;
  }
}
#links-page div.text-bloc > div.linksa ul {
  list-style: none;
  padding: 0;
  margin-left: 30px;
  margin-right: 1em;
}
#links-page div.text-bloc > div.linksa ul li {
  text-align: center;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.3em;
  border: 1px solid $divider-color-on-lightbg;
}
#links-page div.text-bloc > div.texta {
  align-self: stretch;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 1200px) {
  #links-page div.text-bloc > div.texta {
    border-left: $divider-line-width solid $divider-color-on-lightbg;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  #links-page div.text-bloc > div.texta {
    border-left: $divider-line-width solid $divider-color-on-lightbg;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  #links-page div.text-bloc > div.texta {
    border-left: $divider-line-width solid $divider-color-on-lightbg;
  }
}
@media (max-width: 599px) {
  #links-page div.text-bloc > div.texta {
    border-left: none;
  }
}
#links-page div.text-bloc > div.texta h2.texta-title {
  margin-block-start: 0em;
  display: flex;
  justify-self: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.32em;
  margin-block-end: 0.5em;
  font-weight: 100;
}
#links-page div.text-bloc > div.texta h2.texta-title > img {
  height: 50px;
  width: auto;
  filter: invert(50%) sepia(100%);
}
#links-page div.text-bloc > div.texta div.texta-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
#links-page div.text-bloc > div.texta div.texta-columns ul.column {
  margin: 0px;
  padding-left: 20px;
  min-width: 300px;
  width: 25%;
}
#links-page div.text-bloc > div.texta div.texta-columns ul.column li {
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-size: smaller;
  font-weight: 100;
}

/* :: content-short :: */
div#content-short {
  display: flex;
  align-items: center;
  width: 100%;
}
body:not(.interior),body.interior.section-homepage{
div.sec-cell {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1200px) {
  div.sec-cell-sm {
    width: 43.75%;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.sec-cell-sm {
    width: 43.75%;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.sec-cell-sm {
    width: 100%;
  }
}
@media (max-width: 599px) {
  div.sec-cell-sm {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  div.sec-cell-lg {
    width: 56.25%;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.sec-cell-lg {
    width: 56.25%;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.sec-cell-lg {
    width: 100%;
  }
}
@media (max-width: 599px) {
  div.sec-cell-lg {
    width: 100%;
  }
}
div.text-bloc {
  padding: 5px;
}
div.text-bloc > div.icon {
  width: 30%;
}
div.text-bloc > div.icon img {
  width: 100%;
  height: auto;
  filter: invert(50%) sepia(100%);
}
div.text-bloc > div.texta {
  width: 70%;
  padding-left: 1em;
  padding-right: 1.5em;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-left: 2px solid;
}
@media (min-width: 1200px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    font-weight: 100;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    font-weight: 100;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (max-width: 599px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 4vw;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    font-size: 2.4vw;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
div.image-bloc {
  padding: 0px;
}
@media (min-width: 1200px) {
  div.image-bloc {
    display: block;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.image-bloc {
    display: block;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.image-bloc {
    display: none;
  }
}
@media (max-width: 599px) {
  div.image-bloc {
    display: none;
  }
}
div.image-bloc > img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
}
/* :: content-short :: */
div.image-banner {
  border-top: 1px solid $cabq-grey3;
  border-bottom: 1px solid $cabq-grey3;
}
@media (min-width: 1200px) {
  div.image-banner {
    display: block;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.image-banner {
    display: block;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.image-banner {
    display: none;
  }
}
@media (max-width: 599px) {
  div.image-banner {
    display: none;
  }
}
div.image-banner > img {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 22em;
  object-fit: cover;
}
body:not(.interior),body.interior.section-homepage{
div.sec-cell {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
}
div.text-bloc {
  padding: 5px;
}
div.text-bloc > div.icon {
  width: 30%;
}
div.text-bloc > div.icon img {
  width: 100%;
  height: auto;
  filter: invert(50%) sepia(100%);
}
div.text-bloc > div.texta {
  width: 70%;
  padding-left: 2.5em;
  padding-right: 1.5em;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border-left: $divider-line-width solid $divider-color-on-lightbg;
  h2{
    color: #fff;
  }
}
@media (min-width: 1200px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 900px) and (max-width: 1199px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    font-weight: 100;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 1.32em;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
@media (max-width: 599px) {
  div.text-bloc > div.texta h2 {
    text-transform: uppercase;
    font-size: 4vw;
    margin-block-end: 0.5em;
  }
  div.text-bloc > div.texta p {
    font-size: 2.4vw;
    margin-block-start: 0.5em;
    line-height: 1.5em;
  }
}
}
body {
  font-family: 'Public Sans', Helvetica, sans-serif;
  color: $cabq-black;
  display: flex;
  justify-content: center;
  height: $viewportHeight;
  overflow: auto;
  margin: 0px;
  border: 0px;
  padding: 0px;
  font-size: 16px;
}

.wrap {
  min-width: 100%;
  @media (max-width: 1199px){
    margin-top: 70px;
  }  
}


#herospacer {
  height: $navbarHeight;
}

.pagebreak {
  display: block;
  width: 100%;
  height: 12px;
  background-color: white;
}

#main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position:relative;
}
.interior #main #mainleft {
  background-color: $cabq-tan-light;
}
#main #mainright {
  position: relative;
  top: 0px;
  right: 0px;
  background-color: #ffffff;
}

/* main content template specific CSS */
/* :: call-to-action :: */
#call-to-action {
  @media screen and (max-width: 599px){
    height: 31em;
  }
  @media screen and (min-width: 600px){
    height: 22em;
    width: 100%;
  }
  @media screen and (min-width: 1199px){
    height: 13em;
    width: 100%;
    padding-top: 30px;
  }
  overflow: hidden;
  height: 13em;
  display: flex;
  justify-content: space-evenly;
  //width: calc(100vw - 264px);
  //height: calc((100vw - 274px) * 0.49);
}
#call-to-action .aspect-ratio-box-inside {
  @media screen and (max-width: 599px){
    padding-top: 0;
    grid-template-columns: 1fr 1fr;
  }
  position: absolute;
  height: 24vw;
  /* grid configurations */
  display: grid;
  width: 90%;
  justify-content: center;
  align-items: start;
     // grid-template-rows: 1fr 1fr;
  /* grid draw only inner border hack! */
  .action {
    /* stretch to expand the available space & draw border gridline */
    /* flex centering the content <a> vertically & horizontally */
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color-background-call-to-action;
    margin-top: 15px;
    &:hover {
      background-color: $color-background-call-to-action-hover;
      .label {
        color: $color-white-text-hover;
      }
    }
    .action-label {
      min-height: 25px;
      min-width: 25px;
      text-align: center;
      font-size: 1em;
      font-weight: $font-weight-bold;
      color: $cabq-black !important;
      text-decoration: none;
      &:hover{
        color: $cabq-black !important;
      }
      img {
        /* make <img> responsive to screen size */
        width: 90px;
        height: 90px;
        display: block;
        margin: auto;
        background: $cabq-dark-blue;
        border-radius: 50%;
        padding: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@font-face {
$fontsPrefix: '../css/fonts/';

font-family: 'fontello';
src: url('${fontsPrefix}plone-fontello.eot?23168230');
src: url('${fontsPrefix}plone-fontello.eot?23168230#iefix') format('embedded-opentype'),
     url('${fontsPrefix}plone-fontello.woff?23168230') format('woff'),
     url('${fontsPrefix}plone-fontello.ttf?23168230') format('truetype'),
     url('${fontsPrefix}plone-fontello.svg?23168230#fontello') format('svg');
font-weight: normal;
font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
@font-face {
  font-family: 'fontello';
  src: url('@{fontsPrefix}plone-fontello.svg?23168230#fontello') format('svg');
}
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
font-family: "fontello";
font-style: normal;
font-weight: normal;
speak: never;

display: inline-block;
text-decoration: inherit;
width: 1em;
margin-right: .2em;
text-align: center;
/* opacity: .8; */

/* For safety - reset parent styles, that can break glyph codes*/
font-variant: normal;
text-transform: none;

/* fix buttons height, for twitter bootstrap */
line-height: 1em;

/* Animation center compensation - margins should be symmetric */
/* remove if not needed */
margin-left: .2em;

/* you can be more comfortable with increased icons size */
/* font-size: 120%; */

/* Font smoothing. That was taken from TWBS */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

/* Uncomment for 3D effect */
/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* Default Menu Icon */
.toolbar-menu-icon:before { content: '\e834'; }

.icon-user:before { content: '\e800'; } /* '' */
.icon-folderContents:before { content: '\e801'; } /* '' */
.icon-edit:before { content: '\e802'; } /* '' */
.icon-logout:before { content: '\e803'; } /* '' */
.icon-local_roles:before { content: '\e804'; } /* '' */
.icon-history:before { content: '\e805'; } /* '' */
.icon-link:before { content: '\e806'; } /* '' */
.icon-view:before { content: '\e807'; } /* '' */
.icon-collection:before { content: '\e808'; } /* '' */
.icon-event:before { content: '\e809'; } /* '' */
.icon-plone-contentmenu-factories:before { content: '\e80a'; } /* '' */
.icon-contentInfo:before { content: '\e80b'; } /* '' */
.icon-plone-contentmenu-workflow:before { content: '\e80c'; } /* '' */
.icon-moreOptions:before { content: '\e80d'; } /* '' */
.icon-document:before { content: '\e80e'; } /* '' */
.icon-news:before { content: '\e80f'; } /* '' */
.icon-image:before { content: '\e810'; } /* '' */
.icon-file:before { content: '\e811'; } /* '' */
.icon-addon:before { content: '\e812'; } /* '' */
.icon-datetime:before { content: '\e813'; } /* '' */
.icon-caching:before { content: '\e814'; } /* '' */
.icon-markup:before { content: '\e815'; } /* '' */
.icon-editing:before { content: '\e816'; } /* '' */
.icon-errors:before { content: '\e817'; } /* '' */
.icon-imageHandling:before { content: '\e818'; } /* '' */
.icon-filtering:before { content: '\e819'; } /* '' */
.icon-language:before { content: '\e81a'; } /* '' */
.icon-dexterityTypes:before { content: '\e81b'; } /* '' */
.icon-mail:before { content: '\e81c'; } /* '' */
.icon-maintenance:before { content: '\e81d'; } /* '' */
.icon-navigation:before { content: '\e81e'; } /* '' */
.icon-search:before { content: '\e81f'; } /* '' */
.icon-security:before { content: '\e820'; } /* '' */
.icon-theming:before { content: '\e821'; } /* '' */
.icon-rss:before { content: '\e822'; } /* '' */
.icon-siteSettings:before { content: '\e823'; } /* '' */
.icon-userGroups:before { content: '\e824'; } /* '' */
.icon-zope:before { content: '\e825'; } /* '' */
.icon-types:before { content: '\e826'; } /* '' */
.icon-syndication:before { content: '\e827'; } /* '' */
.icon-contentrules:before { content: '\e828'; } /* '' */
.icon-lock:before { content: '\e829'; } /* '' */
.icon-plone-contentmenu-actions:before { content: '\e82a'; } /* '' */
.icon-plone-contentmenu-portletmanager:before { content: '\e82b'; } /* '' */
.icon-plone-contentmenu-display:before { content: '\e82c'; } /* '' */
.icon-calendar-empty:before { content: '\e82d'; } /* '' */
[class^="icon-controlpanel-"]:before { content: '\e849'; } /* '' */
//[class^="icon-controlpanel-"]:hover:before { color: #fff; } /* '' */
.icon-controlpanel-plone_app_caching:before { content: '\e82e'; } /* '' */
.icon-controlpanel-plone_app_registry:before { content: '\e82f'; } /* '' */
.icon-controlpanel-dexterity-types:before { content: '\e830'; } /* '' */
.icon-controlpanel-discussion:before { content: '\e831'; } /* '' */
.icon-controlpanel-ImagingSettings:before { content: '\e832'; } /* '' */
.icon-controlpanel-plone_app_theming:before { content: '\e833'; } /* '' */
.icon-controlpanel-QuickInstaller:before { content: '\e834'; } /* '' */
.icon-controlpanel-DateAndTime:before { content: '\e835'; } /* '' */
.icon-controlpanel-LanguageSettings:before { content: '\e836'; } /* '' */
.icon-controlpanel-MailHost:before { content: '\e837'; } /* '' */
.icon-controlpanel-NavigationSettings:before { content: '\e838'; } /* '' */
.icon-controlpanel-PloneReconfig:before { content: '\e839'; } /* '' */
.icon-controlpanel-SearchSettings:before { content: '\e83a'; } /* '' */
.icon-controlpanel-socialmedia:before { content: '\e83b'; } /* '' */
.icon-controlpanel-syndication:before { content: '\e83c'; } /* '' */
.icon-controlpanel-ContentRules:before { content: '\e83d'; } /* '' */
.icon-controlpanel-EditingSettings:before { content: '\e83e'; } /* '' */
.icon-controlpanel-MarkupSettings:before { content: '\e83f'; } /* '' */
.icon-controlpanel-tinymce:before { content: '\e840'; } /* '' */
.icon-controlpanel-TypesSettings:before { content: '\e841'; } /* '' */
.icon-controlpanel-UsersGroups:before { content: '\e842'; } /* '' */
.icon-controlpanel-errorLog:before { content: '\e843'; } /* '' */
.icon-controlpanel-FilterSettings:before { content: '\e844'; } /* '' */
.icon-controlpanel-SecuritySettings:before { content: '\e845'; } /* '' */
.icon-controlpanel-resourceregistries:before { content: '\e846'; } /* '' */
.icon-emptyset:before { content: '\e847'; } /* '' */
.icon-controlpanel-ZMI:before { content: '\e848'; } /* '' */
.icon-controlpanel-Maintenance:before { content: '\e849'; } /* '' */
.icon-up:before { content: '\e84a'; } /* '' */
.icon-down:before { content: '\e84b'; } /* '' */

.controlPanelSection a{
  &:link, &:visited {
    color: $cabq-dark-blue !important;
  }
}
// MOSAIC EDITING CLEANUP
.mosaic-enabled .outer-wrapper {
  margin-top: 50px;
}
/* EDITING UI CHANGES */
body.template-edit, 
body.viewpermission-add-portal-content,
body.viewpermission-modify-portal-content,
body.viewpermission-redirectiontool-modify-aliases,
/*body.template-document, 
body.template-link, 
body.template-folder, */
body.template-quickedit, 
body.template-folder_contents, 
body.template-base_edit, 
body.template-topbar-manage-portlets,
body.template-select_default_page,
body.template-delete_confirmation,
body.template-sharing{
#banner, .image-banner,.sidebar-offcanvas, #portal-column-one{
  display:none;
}
main#main-container > div {
  width: 100%;
}
#call-to-action {
    width:auto;
}
}
body.template-manage-portlets {
#banner, .image-banner{
  display:none;
}
}
.template-folder_contents a.btn-default{
color:#2b2b2b !important;
}

form .label{
color: $cabq-black;
font-size: 1em;
}
/* transitions for menu openings*/
/*#edit-zone, body  { }
#edit-zone nav > ul ul { } */
#edit-zone nav > ul li.active ul { display: block;}
/* .plone-toolbar-top #edit-zone nav > ul li.active ul {} */

#pfgWidgetWrapper{
top: 10%;
}
body.userrole-authenticated{
  .field, .action, .PFGFieldsetWidget {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) { // reduce H1 size from 36px to 30px on mobile FD8973
h1{
  font-size: 30px;
}
h2{
  font-size: 26px;
}
h3{
  font-size: 22px;
}
h4{
  font-size: 18px;
}
h5{
  font-size: 14px;
}
}
.plone-breadcrumb ol > li{
display: contents;
}
form#siteSearch{
  text-align: center;
  input {
    font-size: 2.5em;
    width: 10em;
    height: 1.1em;
    display: inline-block;
    vertical-align: middle;
  }
  .btn-lg{
    padding: 13px 17px;
  }
  @media (max-width: 768px) {
    input{
      width: 8em;
    }
  }
}
@media (min-width: 1200px){
  .interior #content-long{
    background-color: $cabq-tan-light;
  }
}
@media (min-width: 768px){
  .interior #content-wrap{
    margin: 20px;
  }
}
div#content-wrap>.col-xs-12.col-sm-12 {
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 15px;
}
#viewlet-below-content {
  margin-left: 15px;
}
#viewlet-below-content-body {
  display: inline-block;
  margin-left: 15px;
}
body{
  &:not(.section-homepage).interior{
      .titleandtext .highlightDescription{
        display: none !important;
    }
  }
  &.section-homepage.interior .interiorHighlightDesc,&:not(.interior) .interiorHighlightDesc{
    display: none !important;
    }
}
#searchform .actionMenu { // on Plone search page, pull actionmenu over
  float: right;
}
#portal-breadcrumbs {
  margin: 0; 
  background-color: transparent!important;
}

/*
/* From style.less in cabq2020 theme
*/


// secondary button
$btn-secondary-color: $cabq-black;
$btn-secondary-bg: $cabq-orange1; 
$btn-secondary-border: $cabq-orange1;

// default button
$btn-default-color: $cabq-black;
$btn-default-bg: lighten($cabq-grey2,30%); 
$btn-default-border: $cabq-grey1;

// carat button
$btn-caret-color: #fff;
$btn-caret-bg: $cabq-dark-blue; 
$btn-caret-border: $cabq-dark-blue;
.skip-to-content-link {
  background: #ffffff;
  left: 15px;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}
.skip-to-content-link:focus {
  transform: translateY(0%);
  z-index: 9999;
  position:absolute;
}

#navbar
  a{
  &:link, &:visited, &:active, &:focus{
    color: $cabq-primary;
    @media (max-width:1199px){
      color: $cabq-black;
    }
  }
}
.btn{
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  &:focus,&:focus-visible{
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}
.btn-cancel{
  background-color: $cabq-grey3;
  &:hover, &:active, &:focus{
    background-color: darken($cabq-grey3, 10%);
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
  font-weight: $font-weight-bold;
  line-height: 1.3;
  color: $cabq-primary;
}
#wrap>div {
  padding-left: 0;
  padding-right: 0;
}
#main, #content, footer {
  a{
    &:link,&:active{
      color:$cabq-link;
    }
    &:visited{
      color: $cabq-link;
    }
  }
  img {
    max-width: 100%;
  }

  dt {
      color: $cabq-primary;
  }

  a.btn {
    // bootstrap has white-space set to no-wrap
    white-space: normal;
    color:white;
  }
  // Bringing over the button styles from 2018 theme
  // cabq2018/theme/theme/less/cabq2018-custom.less
  // ------------------------------------------ //
  // buttons
  // ------------------------------------------ //

  .btn {
    border: 0;
    border-radius: 2px;
    outline: none;
    font-size: 1em;
    transition: all 0.3s ease-in-out;
    text-shadow: none;
    &:active,
    &:active:focus,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &.btn-xs,
    &.btn-sm {
      font-size: 14px;
    }

    &.btn-lg {
      font-size: 18px;
    }
  }

  .btn-primary {
    color: $white;
    background-color: $cabq-primary;
    &:hover, &:active, &:focus{
      background-color: darken($cabq-primary, 10%);
      text-shadow: none;
      color: white !important;
    }
  }
  .btn-search {
    background-color: $cabq-dark-blue;
    color: $white;
    &:hover {
      background-color: darken($cabq-dark-blue, 10%);
      color: $white;
    }

    &:active {
      background-color: darken($cabq-dark-blue, 10%);
    }
  }
  .btn-group {
    > .btn {
      padding: 0.875rem 1.25rem;
      border: 1px solid $cabq-dark-blue-20;
      font-weight: $font-weight-normal;
      text-align: left;
      &:hover,
      &:focus {
        // background-color: $white;
        border-color: $cabq-dark-blue-20;
        color: $cabq-link;
      }

      &:active {
        box-shadow: none;
      }

      .cabq-visitor-help &.dropdown-toggle {
        .glyphicon {
          position: absolute;
          top: 1.125rem;
          right: 1.5rem;
          float: right;
        }
      }
    }

    &.open {
      .dropdown-toggle {
        border: 1px solid $cabq-dark-blue-20;
        box-shadow: none;
      }
    }
  }

  .btn-default.active,
  .btn-default:active,
  .open > .dropdown-toggle.btn-default,
  .open > .dropdown-toggle.btn-default:hover,
  .open > .dropdown-toggle.btn-default:focus {
    background-color: lighten($cabq-grey1, 60%);
    border-color: $cabq-grey1;
    color: $cabq-grey1;
  }
  .btn-danger {
    color: white;
    background-color: #c9302c;
    border-color: #ac2925;
    &:hover, &:focus, &:active {
      background-color: darken(#c9302c, 10%);
      color: #fff !important;
    }
  }
  .btn-info {
    color: #fff;
    background-color: lighten($cabq-dark-blue, 6%);
    &:hover, &:focus, &:active {
      background-color: darken($cabq-dark-blue, 5%);
      color: #fff !important;
    }
  }
  .btn-success {
    color: #fff;
    background-color: #3D843E;
    border-color: #398439;
    &:hover, &:focus, &:active {
      background-color: darken(#3D843E, 10%);
      border-color: darken(#3D843E, 10%);
      color: #fff !important;
    }
  }
  .btn-warning {
    color: #fff;
    background-color: darken($cabq-orange-dark,10%);
    border-color: $cabq-orange-dark;
    &:hover, &:focus, &:active {
      background-color: darken($cabq-orange-dark,15%);
      border-color: darken($cabq-orange-dark,15%);
      color: #fff !important;
    }
  }
  .mosaic-tile-content .sections,  .mosaic-tile-content #hero>#focusnow .story {
      section{
        position:relative;
      }
    a.extLinkGlobe {
      @extend .btn;
      @extend .btn-sm;
      @extend .btn-primary;
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      float: right;
      color: #fff !important;
    }
  }
  
  // input[type="text"], input[type="email"], input[type="password"], textarea, select {
  //     display: block;
  //     // removing due to test renderer form from the donated theme
  //     width: 100%;
  //     height: 34px;
  //     padding: 6px 12px;
  //     font-size: 14px;
  //     line-height: 1.42857143;
  //     color: #696969;
  //     background-color: #fff;
  //     border: 1px solid #ccc;
  //     border-radius: 4px;
  //     -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  //     box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  //     -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  //     -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  //     transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  //     color: $cabq-black;
  // }
  input:hover {
    background-color: $input-hover;
  }

  legend {
    color: $cabq-primary;
  }

  .documentDescription, .lead { // Plone6 .documentDescription class  now called .lead
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 1em;
  }
  .field.error {
    background: #fbe8e4;
    -webkit-box-shadow: 0 0 0 5px #fbe8e4;
    box-shadow: 0 0 0 5px #fbe8e4;
  }
  .field.error > label, .field.error div.error {
    color: $form-error;
  }
  .field.error input, .field.error textarea, .field.error select {
    border: 1px solid $form-error;
  }
  .formHelp {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 200;
    font-style: italic;
  }
  input[type="submit"], input[type="button"] {
    @extend .btn;
    @extend .btn-primary;
  }
  input.context {
    @extend .btn;
    @extend .btn-primary;
  }
  input.standalone {
    @extend .btn;
    @extend .btn-secondary;
  }
  input.warning {
    background-color: #f0ad4e;
    border-color: #eea236;
    &:hover {
      background-color: #ec971f;
      border-color: #d58512;
    }
  }
  input.destructive {
    background-color: #d9534f;
    border-color: #d43f3a;
    &:hover {
      background-color: #c9302c;
      border-color: #ac2925;
    }
  }
}


// PORTLETS

@mixin h3-styles {
  // Add the common styles for h3 here
  font-size: 1.5rem;
  font-weight: bold;
  // Add any other h3 styles
}

@mixin cabq-module-h3-styles {
  // Add the specific styles for .cabq-module h3 here
  // For example:
  color: #333;
  // Add any other specific styles
}

@mixin cabq-module-ul-nav-styles {
  // Add the specific styles for .cabq-module ul.nav here
  // Example styles:
  padding: 0;
  margin: 0;
  // Add any other specific styles
}

@mixin nav-styles {
  // Add the specific styles for .nav here
  // Example styles:
  list-style: none;
  padding-left: 0;
  // Add any other specific styles
}

@mixin nav-ul-styles {
  // Add the specific styles for .nav ul here
  // Example styles:
  margin: 0;
  padding: 0;
  // Add any other specific styles
}

@mixin event-h4-styles {
  // Add the specific styles for .cabq-module .event h4 here
  // Example styles:
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  // Add any other specific styles
}
.panel .portletNavigationTree .panel-body ul li a:hover::after, 
.portletNavigationTree .card-body ul li a:hover::after, 
.portletNavigationTree .panel .panel-body ul li a:hover::after, 
.portletNavigationTree .well ul li a:hover::after {
  content:none;
}
.portlet {
  margin-bottom: 20px;
  border-radius: 4px;
  border: 0;
  box-shadow: none;

  &.managedPortlet {
    background: $cabq-dark-blue-10;
    padding: 5px 10px 0 10px;

    .managedPortletActions {
      margin-top: 0;
    }

    .card-header {
      font-size: 16px;

      a {
        float: left;
        font-size: 16px;
      }
    }
  }
  &.portletQuickNavPortlet a:hover {
    text-decoration: underline;
  }
  .card-header, .portletHeader {
    @include h3-styles;
    @include cabq-module-h3-styles;
    background: none;
    font-weight: 700;
    padding: 0;
    border-bottom: 1px solid $cabq-grey2;
    padding-bottom: 10px;
    margin: 1.95rem 0 0.95rem;
    color: $cabq-dark-blue;
    font-size: 1.25rem;
    h3,
    a {
      @include h3-styles;
      @include cabq-module-h3-styles;
      display: block;
      font-size: 1.25rem;
    }

    a {
      margin: 1.25rem 0 0;
    }
  }

  .card-body {
    @include cabq-module-ul-nav-styles;
    @include nav-styles;
    @include nav-ul-styles;
    background: none;
    border: 0;
    list-style: none;

    > li {
      line-height: 2;
    }

    > ul > li a {
      color: $cabq-dark-blue;
      padding: 0;

      &::before {
        content: '';
      }

      &:hover {
        color: $cabq-red;
        background-color: $white;
      }
    }

    > ul > li > a {
      border: 0;
    }

    > ul .portletItemDetails {
      margin-top: 0;
      padding-left: 0;
    }
  }

  .portletItem {
    padding: 0 0 10px;

    ul {
      line-height: 2;
      padding: 10px 0 10px 20px;
    }

    .tile {
      @include event-h4-styles;
    }
  }

  .image-icon {
    display: none;
  }

  .portletFooter {
    background-color: transparent;
    border-top: 1px solid $cabq-grey2;
    padding: 10px 0;
  }
}

// PORTLETS - TYPES
@mixin nav-hover-styles {
  background-color: $cabq-primary;
  text-decoration: none;
  color: #fff !important;
}

@mixin navTreeTopNode-hover-styles {
  background: none;
}

@mixin navTreeCurrentNode-styles {
  background-color: $cabq-secondary;

  &::after {
    content: '';
  }

  &:active, &:focus {
    background-color: $cabq-secondary;
  }

  &:hover {
    color: $cabq-black !important;
    background-color: $cabq-secondary;
  }
}

#main .portlet.portletNavigationTree {
  border: none;
  background-color: white;
  .visualNoMarker .plone-icon { // hide the icon in the portlet navigation
    display: none;
  }
  .card-header {
    display: none;
  }

  nav > ul > li {
    a {
      background: none;
      border: 0;
      padding: 10px 5px 10px;
      display: flex;
      font-weight: $font-weight-bold;

      &:hover {
        @include nav-hover-styles;
      }

      &:link, &:visited {
        color: $cabq-black;
      }
    }

    &.navTreeTopNode a {
      @include h3-styles;
      @include cabq-module-h3-styles;
      padding: 0;

      &:hover {
        @include navTreeTopNode-hover-styles;
      }
    }
  }

   .navTreeCurrentNode {
    > a {
      @include navTreeCurrentNode-styles;
    }
  }

  ul {
    padding: 0;

    &.navTreeLevel1 {
      margin-left: 28px;
    }

    &.navTreeLevel2, &.navTreeLevel3, &.navTreeLevel4 {
      margin-left: 24px;
    }

    li {
      list-style-type: none;
      border-top: 0px;
    }
  }
}
.navTreeLevel0>.navTreeItem>a.navTreeFolderish::before{
  padding-left: 15px;
  background: url(/++theme++cabq-plone-6-theme/_assets/img/subnavitem.svg);
  border: 0;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 7px;
  height: 1.3em;
  margin-right: 6px;
}

.navTreeLevel0>.navTreeItem.navTreeItemInPath, .navTreeLevel0>.navTreeItem.navTreeCurrentNode{
  &>a.navTreeFolderish::before{
  transform: rotate(90deg);
}
}
.navTreeLevel0>.navTreeItem>a:not(.navTreeFolderish)::before{
  padding-left: 30px;
}
.navTreeItem img.thumb-icon {
  margin-right: 5px;
}
.portletNews {
  .portletContent > ul {
    .portletItemDetails {
      display: none;
    }
  }
  .portletFooter {
    display: none;
  }
}
#portlet-contactPortlet .portletFooter{
	font-size:14px;
}
@mixin event-h4-styles {
  // Add the specific styles for .cabq-module .event h4 here
  // Example styles:
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  // Add any other specific styles
}

@mixin event-date-styles {
  // Add the specific styles for .cabq-module .event .event-date here
  // Example styles:
  font-size: 1rem;
  color: #666;
  // Add any other specific styles
}

.portletEvents {
  .portletItem {
    .tile {
      @include event-h4-styles;
    }
  }

  .event-date {
    @include event-date-styles;
  }

  h4 {
    @include event-h4-styles;
  }

  .dtstart {
    display: none;
  }
}

@mixin cabq-module-ul-styles {
  // Add the specific styles for .cabq-module ul here
  // Example styles:
  list-style-type: none;
  margin: 0;
  padding: 0;
  // Add any other specific styles
}

@mixin cabq-content-cabq-list-styles {
  // Add the specific styles for .cabq-content .cabq-list here
  // Example styles:
  margin-bottom: 1rem;
  line-height: 1.5;
  // Add any other specific styles
}

.portletStaticText {
  .portletContent > ul {
    @include cabq-module-ul-styles;
    @include cabq-content-cabq-list-styles;
    padding: 10px 0 10px 20px;
  }
}

.portlet.portletCalendar {
  &::before {
    content: 'Calendar';
    display: block;
    @include h3-styles;
    @include cabq-module-h3-styles;
  }

  table thead {
    background: none;
    border: 0;
  }

  .card-header {
    font-size: 14px;
    font-weight: normal;
    padding: 10px 0 0;
    text-align: center;

    .calendarPrevious,
    .calendarNext {
      color: $cabq-medium-blue;
      cursor: pointer;
      font-size: 10px;
      line-height: 20px;
      padding: 4px 0;
      text-transform: uppercase;

      &:hover {
        background: none;
        color: $cabq-blue;
      }
    }

    .calendarPrevious {
      float: left;
    }

    .calendarNext {
      float: right;
    }
  }

  .ploneCalendar {
    background: none;
    border: 0;
    text-align: center;
    width: 100%;

    &.cal_prev_month,
    &.cal_next_month {
      span {
        visibility: hidden;
      }
    }

    span {
      color: $dark-grey; // Changed from @dark-grey to $dark-grey
    }

    .weekdays th {
      background: none;
      border: 0;
      color: darken($cabq-secondary, 5%);
      font-weight: $font-weight-normal;
      text-align: center;
      text-transform: uppercase;
    }

    .event {
      background: none;
      border: 0;
      padding: 10px 0;

      &.today {
        background: none;

        span {
          background: none;
          box-shadow: none;
          color: $dark-grey; // Changed from @dark-grey to $dark-grey
          font-weight: $font-weight-bold;
        }
      }

      &.cal_has_events {
        a {
          color: $cabq-secondary;

          &:after {
            content: '';
          }
        }
      }
    }
  }
}


.portlet.portletGoogleTransitWidget{
    margin-bottom:0;
}
.g-ttype, .g-date, .g-time, .g-wplab{
    width: 100% !important;
}
.g-planner{
    width:100% !important;
}

// End cabq2018/theme/theme/less/cabq2018-custom.less stuff


.plone-breadcrumb ol {
    display: inline-block;
    padding: 15px 0 2px 0;
    list-style: none;
    margin: 0 0 15px 0;
    border-bottom: 1px solid $cabq-dark-blue;
    width: 100%;
    color: $cabq-dark-blue;
    & > li {
        font-weight: 400;
        display: contents;
        line-height: 0;
        position: relative;
        padding: 0 5px 0 0;
        text-transform: uppercase;
        & + li::before {
            padding: 0 5px;
            content: ">";
        }
    }
}

.plone-toolbar-left-expanded {
  padding-left: 120px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}


#navbar-large {
    margin-left: -15px;
    margin-right: -15px;
}

.searchButton {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.searchField {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
}


#navbar > #navbar-large #navsect .link-group ul.dropdown-menu {
  display: none;
}

#navbar > #navbar-large #navsect .link-group ul.dropdown-menu.show {
    display: block;
    height: fit-content;
    margin-top: -2px !important;
}

/*
******
Temporary measure for long navigations. Hide 7th one and beyond
******
*/
/* #portal-globalnav li:nth-child(n+7) {
  display: none;
} */

/* Undo the container given by the navigation */
#portal-mainnavigation {
  padding-left: 15px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: 13px;
}

.plone-navbar-toggle {
  display: none;
}

.action-label, .uppercase {
  text-transform: uppercase;
}



  /*.mosaic-tile-content {
    #hero {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  */
  a#hero_tile:hover {
    color: inherit;
    text-decoration: none;
}
// FD 9653 - lots of overrides added in this section so we didn't have to touch the designer's orginal layout.less
#hero {
  .story {
    background-color: $cabq-dark-blue;
    padding: 20px;
    border: none;
    height: 200px;
    width: 500px;
    position:relative;
    color: #fff;
    display:flex;
    @media (max-width:599px){
      width: 90vw;
    }
    .icon{
      min-width: 10em;
      max-width: 10em;
      border-right: 2px solid #fff;
      display:inline-block;
      padding-top: 8px;
      @media (max-width:599px){
        min-width: 8em;
        max-width: 8em;
      }
    }
    @media (min-width: 1200px){
      .icon{
          >img {
            margin:inherit !important;
            height: inherit !important;
          }
        }
    }
    .texta {
      color: #fff;
      padding-left: 15px;
      display:inline-block;
      @media (max-width: 599px){
          width: 100% !important;
          text-align: left !important;
      }
      >h1{
        font-size: 28px;
        line-height: 120%;
        font-weight: $font-weight-bold;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        &:after {
          border-bottom: none !important;
        }
      }
      >p{
        font-size: 16px !important;
        @media (min-width: 1200px){
          margin-left: 0px !important;
        }
      }
    }
  }
}
  .mosaic-grid-row:nth-child(n+2) {
   /* width: calc(~"100% - 205px");
    
    margin-left: -45px;
    margin-right: -45px;
    */
      @media screen and (max-width: 1200px){
         width:100%;
         margin: 0;
    }
    .mosaic-tile {
      margin: 12px 0;
    }
  }
  #mainleft {
    @media screen and (min-width: 1200px) {
      width: 100%;
    }
  }
  #mainright {
    @media screen and (min-width: 1200px) {
      width: initial;
    }
  }


.hidden {
    display: none
}
.hiddenStructure {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
body:not(.interior),body.interior.section-homepage{
  .sections section:nth-child(even) a > div.sec-row {
      flex-direction: row-reverse;
      color: white;
      background-color: $cabq-primary;
      .icon img {
        filter: inherit !important;
      }
      div.texta {
        border-left: 2px solid white !important;
      }
  }

  div.mosaic-cabq\.sectionhighlight-tile {
    div.sections section div.sec-row {
      color: white;
      background-color: $cabq-dark-blue; 
      a {
        color: white !important;
      }
      .icon img {
        filter: inherit !important;
      }
      div.texta {
        border-left: 2px solid white;
        .linkArrow{
          display:none;
        }
        @media (max-width: 899px) {
        border-left: 0;
      }
      }
    }
    margin: 0px !important;
    @media (max-width: 768px){
      margin: 0 -30px 0 -15px !important;
    }
    &:nth-child(even) {
    div.sections section div.sec-row {
      flex-direction: row-reverse;
    }
    }
  }
}

body:not(.section-homepage).interior {
  .mosaic-cabq\.sectionhighlight-tile {
    @media (min-width: 1199px){
      margin-bottom: 5em;
  }
  .sections {
    a:link, a:visited, a:hover, a:focus, a:active{
      color: $cabq-black !important;
    }
    a:hover{
      text-decoration: underline $cabq-black;
    }
    .sec-row{
      display: flex;
      flex-direction: column-reverse;
      .sec-cell{
        .texta {
          display: flex;
          justify-content: space-between;
          .titleandtext h2{
            margin-top:10px;
            line-height: 1.2em;
          }
          .linkArrow {
            width: 25px;
          }
        }
        .highlightDescription{
          position: absolute;
          margin-left: 15px;
        }
        .icon {
          display:none;
        }
        &.image-bloc img{
          border-radius: 6px;
          aspect-ratio: 16/9; // force 16:9 ratio
          object-fit: cover; // image resizes correctly
          object-position: center center;
          width: -webkit-fill-available;
          width: -moz-available;
        }
        &.highlight-textbloc{
          border-radius: 0 0 6px 6px;
          background-color: $cabq-secondary;
          padding: 0 15px;
          position: absolute;
          bottom: 0;
          width: 100%;
          &:hover{
            text-decoration: underline;
          }
          h2, p{
            color: $cabq-black;
          }
        }
      }
    }
  }
  }
  div:not(.mosaic-width-full).mosaic-grid-cell{
    .mosaic-cabq\.sectionhighlight-tile {
      .interiorHighlightDesc{
        display:none;
      }
      @media (min-width: 1199px){
        margin-bottom: 1em;
      }
      @media(max-width:1198px){
        margin-bottom: 1em;
      }
      .interiorHighlightDesc{ // don't display description on non-full width highlights on small devices
        @media (max-width:768px){
          display: none;
        }
      }
    }
  }
  .mosaic-grid-cell.mosaic-width-full .mosaic-cabq\.sectionhighlight-tile {
    margin-bottom: 3em;
    @media (max-width:768px){
      .interiorHighlightDesc{
        @media (max-width:768px){
          display:none;
        }
      }
    }
  }
}
.mosaic-grid-cell{ // Plone 6 Mosaic adds .row 
  @extend .col;
}

body:not(.interior),body.interior.section-homepage #content-long {
  .mosaic-width-full {
    min-height: inherit;
    padding-left: 0;
    padding-right: 0;
  }
}


.panel {
  @extend .card;
  border: 0;
  box-shadow: none;
  .panel-heading {
    @extend .card-header;
    border-radius: 2px;
    padding: 7px 10px;
    h1, h2, h3, h4, h5, h6{
      margin: 0;      
    }
    &.active {
      border: 0;
      .panel-title {
        a {
          color: $white;
        }
      }
    }
    + .panel-collapse {
      > .panel-body {
        border: none;
        padding: 1.25rem 0.375rem;
      }
    }
  }

  .panel-title {
    line-height: 1.25rem;
    @extend .card-title;
    a {
      display: block;
      padding: 0.875rem;
    }
  }
  .panel-body{
    border: 1px solid $cabq-primary;
    margin-top: -1px;
    @extend .card-body;
    margin-bottom: 20px;
  }
}
.panel-group {
  .panel-default {
    border-radius: 0;
  }
}
.panel-primary, .panel-default{
  border-color:$cabq-primary;
  &.active{
    border-color:$cabq-primary;
  }
  >.panel-heading{
    background-color: $cabq-primary;
    color:white;
  }
}
.panel-secondary{
  border-color:$cabq-secondary;
  &.active{
    border-color:$cabq-secondary;
  }
  >.panel-heading{
    background-color: $cabq-secondary;
    color:white;
  }
  .panel-body{
    border-color: $cabq-secondary;
  }
}
.card.card-primary{
  .card-header{
    background-color: $cabq-dark-blue;
    color: white;
    border-radius: 2px;
    padding: 7px 10px;
    border-color: #01636A;
    border-bottom: 1px solid transparent;
    h2{
      color: white;
    }
    a{
      color:white;
    }
  }
}
#content .card{
  margin-bottom: 15px;
}
#content-core{
	.panel-primary .panel-heading h2 {
	    color: #fff;
      margin: 0px;
    }
}
// margin nav-tabs within a panel-heading
.panel-heading{
  .nav-tabs, .nav-pills {
    padding: 10px 0 0px 10px;
    margin-bottom: -1px;
  }
}
.panel-body{
  border-radius: 0px 0px 4px 4px;
  border: 1px solid $cabq-grey3;
}
body:not(.homepage) {
  #content {
    .panel-default,.panel-primary,.panel-secondary{
      .panel-heading li:not(.active) a:link:not(:hover,:focus) {
          color: white;
      }
    }
  }
  div#content-wrap>.col-12.col-sm-12 {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 15px;
  }
}
// PFG Overrides
#pfgWidgetWrapper {
  top: 10%;
}
.portaltype-formfolder {
    input[type="radio"], input[type="checkbox"] {
        margin-left: 2px;
    }
   label, .label { // reset font weight for form labels
      font-weight: normal;
   }
   label.formQuestion,.label.formQuestion{  // set bold for only form question labels
     font-weight: bold;
   }
    .label{
        padding: .2em 0 .7em;
        vertical-align: top;
        white-space: normal;
        color: $cabq-primary;
        font-size: 1.4rem;
        margin-top: 1.3rem;
        text-align: left;
        display: block;
    }
    fieldset{
        border-top: 1px solid #ccc;
    }
    .formHelp{
      margin-bottom: 5px;
      white-space: normal;
	    text-align: left;
      margin-left: 5px;
      display:block;
      line-height: 1.4em;
      font-style: italic;
    }
    .field, .action, .PFGFieldsetWidget {
    	width: 100%;
    }
    select[multiple], select[size] {
      min-width: auto;
    }
}

.formControls {
    border-top: 1px solid #ccc;
    background: #fcfcfd;
    padding: 12px;
    box-shadow: inset 0 10px 10px -10px rgba(0,0,0,0.05);
}

.field {
    margin-bottom: 15px;
}

select {
    width: unset;
    display: inline-block;
}

label, div.label{
  .required::after {
    color: rgb(196, 24, 60);
    content: "•";
    font-size: 200%;
    line-height: 0;
    position: relative;
    margin-left: -4px;
  }
}

textarea {
    min-height: 9em;
    min-width: 10em;
    resize: vertical;
}

/* AIR QUALITY */
.portletAirQualityPortlet {
  margin-bottom: 25px;
  padding:7px;
    background-color: #fff;
    border: 1px #ccc solid;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.05);
  .portletItem {
	  padding: 0;
	  line-height: 32px;
	  font-weight: 700;
	}
	.aqi {
	  background: #fff url(../img/icon-aqi-small.png) no-repeat 3px 3px;
	  padding-left: 41px;
	}
	.pollen {
	  background: #fff url(../img/icon-pollen-small.png) no-repeat 5px 3px;
	  padding-left: 41px;
	}
	.status-text {
	  padding: 7px;
	  line-height: 1.5em;
	  font-weight: 700;
	}
	#today-status-link {
	  text-decoration: none;
	}
	.alertsSignup {
	  color: #467C98;
	  font-size: 11px;
	  font-weight: 700;
	}
	#aqportlet-alerts, #aqportlet-currentConditions {
	  text-align: center;
	  margin: 5px 5px 0 0;
	}
	.burn {
	  background: #fff url(../img/icon-burn-small.png) no-repeat 2px 3px;
	  padding-left: 41px;
	}
}

// Airquality Page
#airstatus-data {
  width: 100%;
  border-collapse: collapse;
  color: #272727;
  td {
	  width: 33%;
	  padding: 0;
	}
	.status-label {
	  background-color: #fff;
	  line-height: 44px;
	  padding-left: 65px;
	  font-size: 20px;
	  margin-bottom: 1px;
	}
	#label-aqi {
		background: #fff url(../img/icon-aqi-large.png) no-repeat 15px 3px;
	}
	#label-pollen,#label-pollen-east,#label-pollen-west
	{
	  background: #fff url(../img/icon-pollen-large.png) no-repeat 15px 3px;
	}
	#label-burn {
	  background: #fff url(../img/icon-burn-large.png) no-repeat 15px 3px;
	}
}
#status-header {
  text-align: right;
  padding: 3px 12px 6px;
  margin-right: 2px;
  #status-date {
	  color: #fff important;
	  padding-top: 4px;
  }
  h2 {
	  border-bottom-width: 0;
	  float: left;
	  margin: 0;
	  font-weight: 700;
	}
  h2,a{
  background-color: #333;
  color: #fff!important;
  }
}
#airstatus-data {
  width: 100%;
  border-collapse: collapse;
  color: #272727;
}
#airstatus-data td {
  width: 33%;
  padding: 0;
}
#airstatus-data .status-label {
  background-color: #fff;
  line-height: 44px;
  padding-left: 65px;
  font-size: 20px;
  margin-bottom: 1px;
}
#airstatus-data #label-aqi {
  background: #fff url(../img/icon-aqi-large.png) no-repeat 15px 3px;
}
#airstatus-data #label-pollen,
#airstatus-data #label-pollen-east,
#airstatus-data #label-pollen-west {
  background: #fff url(../img/icon-pollen-large.png) no-repeat 15px 3px;
}
#airstatus-data #label-burn {
  background: #fff url(../img/icon-burn-large.png) no-repeat 15px 3px;
}
#status-header {
  text-align: right;
  padding: 0px 12px 11px;
  margin-right: 2px;
}
#status-header,
#status-header h2,
#status-header a {
  background-color: #333;
  color: #fff!important;
}
#status-header h2 {
  border-bottom-width: 0;
  float: left;
  margin: 0;
  font-weight: 700;
}
#status-header #status-date {
  color: #fff important;
  padding-top: 11px;
}
.status-text.specialConditions {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.status-text.specialConditions h4 {
  margin-top: 0;
  color: inherit;
}
.status-text.specialConditions .alert-link {
  font-weight: bold;
}
.status-text.specialConditions > p,
.status-text.specialConditions > ul {
  margin-bottom: 0;
}
.status-text.specialConditions > p + p {
  margin-top: 5px;
}
.status-text.specialConditions hr {
  border-top-color: #a6e1ec;
}
.status-text.specialConditions .alert-link {
  color: #245269;
}
#airstatus-data .status-text {
  line-height: 1.5em;
  padding: 9px;
  font-weight: 700;
}
.template-airqualityhomepage_view #airstatus-data .status-text {
  padding: 0 3px;
  text-align: center;
}
#airstatus-data .status-label,
#airstatus-data .status-text {
  margin: 6px 2px 0 0;
}
#status-footer {
  text-align: right;
  margin-bottom: 60px;
}
#status-footer #footer-more {
  float: left;
}
#air-topics {
  width: 100%;
  border-collapse: collapse;
  color: #272727;
  margin-top: 35px;
}
#air-topics th,
#air-topics td {
  border: 6px solid #DFDDCE;
}
#air-topics th {
  padding: 0;
  vertical-align: top;
  height: 62px;
}
#air-topics th.live {
  background: #fff url(../img/topic-live.jpg) no-repeat left top;
}
#air-topics th.work {
  background: #fff url(../img/topic-work.jpg) no-repeat left top;
}
#air-topics th.data {
  background: #fff url(../img/topic-data.jpg) no-repeat left top;
}
#air-topics td {
  width: 33%;
  padding: 0;
  vertical-align: top;
  background-color: #fff;
}
.template-airqualitytodaystatus_view .status-label {
  margin-top: 1px;
}
.Good,
.Low,
.None {
  background: #00E400;
  color: #000!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Good h4,
.Low h4,
.None h4 {
  margin-top: 0;
  color: inherit;
}
.Good .alert-link,
.Low .alert-link,
.None .alert-link {
  font-weight: bold;
}
.Good > p,
.Low > p,
.None > p,
.Good > ul,
.Low > ul,
.None > ul {
  margin-bottom: 0;
}
.Good > p + p,
.Low > p + p,
.None > p + p {
  margin-top: 5px;
}
.greenSaturated {
  background: #00E400;
}
.Moderate,
.Medium,
.Voluntary,
.Other {
  background: #FF0;
  color: #000!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Moderate h4,
.Medium h4,
.Voluntary h4,
.Other h4 {
  margin-top: 0;
  color: inherit;
}
.Moderate .alert-link,
.Medium .alert-link,
.Voluntary .alert-link,
.Other .alert-link {
  font-weight: bold;
}
.Moderate > p,
.Medium > p,
.Voluntary > p,
.Other > p,
.Moderate > ul,
.Medium > ul,
.Voluntary > ul,
.Other > ul {
  margin-bottom: 0;
}
.Moderate > p + p,
.Medium > p + p,
.Voluntary > p + p,
.Other > p + p {
  margin-top: 5px;
}
.yellowSaturated {
  background: #FF0;
  color: #000!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.yellowSaturated h4 {
  margin-top: 0;
  color: inherit;
}
.yellowSaturated .alert-link {
  font-weight: bold;
}
.yellowSaturated > p,
.yellowSaturated > ul {
  margin-bottom: 0;
}
.yellowSaturated > p + p {
  margin-top: 5px;
}
.Unhealthy-for-Sensitive-Groups,
.orageSaturated {
  background: #FF7D00;
  color: #fff!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Unhealthy-for-Sensitive-Groups h4,
.orageSaturated h4 {
  margin-top: 0;
  color: inherit;
}
.Unhealthy-for-Sensitive-Groups .alert-link,
.orageSaturated .alert-link {
  font-weight: bold;
}
.Unhealthy-for-Sensitive-Groups > p,
.orageSaturated > p,
.Unhealthy-for-Sensitive-Groups > ul,
.orageSaturated > ul {
  margin-bottom: 0;
}
.Unhealthy-for-Sensitive-Groups > p + p,
.orageSaturated > p + p {
  margin-top: 5px;
}
.Unhealthy,
.High,
.Mandatory,
.redSaturated {
  background: red;
  color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Unhealthy h4,
.High h4,
.Mandatory h4,
.redSaturated h4 {
  margin-top: 0;
  color: inherit;
}
.Unhealthy .alert-link,
.High .alert-link,
.Mandatory .alert-link,
.redSaturated .alert-link {
  font-weight: bold;
}
.Unhealthy > p,
.High > p,
.Mandatory > p,
.redSaturated > p,
.Unhealthy > ul,
.High > ul,
.Mandatory > ul,
.redSaturated > ul {
  margin-bottom: 0;
}
.Unhealthy > p + p,
.High > p + p,
.Mandatory > p + p,
.redSaturated > p + p {
  margin-top: 5px;
}
.Very-Unhealthy,
.Very-High,
.purpleSaturated {
  background: #99004C;
  color: #fff!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Very-Unhealthy h4,
.Very-High h4,
.purpleSaturated h4 {
  margin-top: 0;
  color: inherit;
}
.Very-Unhealthy .alert-link,
.Very-High .alert-link,
.purpleSaturated .alert-link {
  font-weight: bold;
}
.Very-Unhealthy > p,
.Very-High > p,
.purpleSaturated > p,
.Very-Unhealthy > ul,
.Very-High > ul,
.purpleSaturated > ul {
  margin-bottom: 0;
}
.Very-Unhealthy > p + p,
.Very-High > p + p,
.purpleSaturated > p + p {
  margin-top: 5px;
}
.Hazardous,
.maroonSaturated {
  background: #4C0026;
  color: #fff!important;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.Hazardous h4,
.maroonSaturated h4 {
  margin-top: 0;
  color: inherit;
}
.Hazardous .alert-link,
.maroonSaturated .alert-link {
  font-weight: bold;
}
.Hazardous > p,
.maroonSaturated > p,
.Hazardous > ul,
.maroonSaturated > ul {
  margin-bottom: 0;
}
.Hazardous > p + p,
.maroonSaturated > p + p {
  margin-top: 5px;
}
.template-statuspollen_view #pollen-data label {
  display: block;
  margin-top: 10px;
}
.template-statusburn_view #burn-data label {
  display: block;
  margin-top: 10px;
}
.template-airqualitytodaystatus_view #status-date {
  float: right;
  padding-left: 20px;
}
.template-listing_view article.entry {
  clear: both;
  margin-bottom: 2em;
}
.template-shortcontent_view {
  #content-long {
    .col-xs-12 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#sidethreenavwithicon {
  img {
    width: 40px;
    height: 40px;
  }
  div {
    display: inline-block;
  }
}

.mobile-quick-nav {
  ul {
    padding: 0 !important;
  }
}

#navbar-mini li,
#rightnav li {
  text-transform: uppercase;
  text-align: center;
  font-weight: $font-weight-bold;
}
#navbar > #navbar-mini > ul{
  padding-bottom: 0;
}
@media screen and (min-width:1200px) {
#portal-globalnav {
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: flex-end;
  &>li.has_subtree>a.show {
      border-bottom: 8px orange solid;
      padding-bottom: 10px;
  }
  .show > a, .show > a:hover, .show > a:focus {
    background-color: transparent;
    border-color: transparent;
  }
  &>li {
    text-transform: uppercase;
    text-align: center;
    font-weight: $font-weight-bold;
  }
  .has_subtree{ 
    position: static;
      .has_subtree {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 30px 10vw 30px 14vw;
        top: 98px;
        width: 100%;
        column-count: 3;
        border-top: 1px solid lighten($cabq-grey2, 30%);
        border-bottom: 1px solid lighten($cabq-grey2, 30%);
        margin-top: 17px;
        border-radius: 0;
        > li {
          :hover{
            background-color: $cabq-tan-light;
          }
          > a {
            color: $cabq-dark-blue;
            padding: 0.75rem 1.25rem;
            &:hover, &:focus {
              color: $cabq-dark-blue;
              background-color: $white;
            }
          }
    
          &:last-child {
            a {
              border-bottom: none;
            }
          }
          a {
            font-weight: $font-weight-bold;
            text-transform: capitalize;
            font-size: 1.1em;
            line-height: 1em;
          }
        }
      }    
  }
}
}

#portal-globalnav .has_subtree a {
  .arrowbox{
      background: $cabq-primary;
      height: 17px;
      width: 17px;
      display: inline-block;
      border-radius: 4px;
      margin-left: 5px;
      margin-top: -1px; 
  }
  .arrow.down {
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 5px;
    margin-left:auto;
    margin-right: auto;
  } 
}

#navbar-mini .has_subtree {
 .dropdown-menu{
    display:none; //initially hide mini subnav
    li a {
        padding-left: 20px;
    }
  }
a{
  display:flex;
  align-items: center;
  .arrowbox{
    background: #01636A;
    height: 20px;
    width: 20px;
    display: inline-block;
    border-radius: 4px;
    margin-left: 5px;
    padding-left: 6px;
    .arrow.down {
      border: solid white;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      margin-bottom: 10px;
    } 
  }
}
}

@media screen and (max-width:1199px) {
  .has_subtree .btn.btn-caret{
    float: left;
    margin-right: 7px;
    line-height: 1.3em;
    padding: 0px 5px;
    margin-top: 2px;
  }
    .show {
      .dropdown-menu {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        background-color: #f3f1ee;
        display: block;
        width: 89vw;
        padding-top: 1em;
        font-size: 1em;
        left: 0;
        padding-left: 0 !important;
      &>li>a{
        font-weight: $font-weight-bold;
        white-space:unset;
      }
    }
  }
  .translateButton, .siteSearchButton{
    display: none;
  }
  .mobile-quick-nav {
    font-size: .9em;
    a{
      color: $cabq-black;
    }
    background: $cabq-secondary;
    display: block;
    margin-left: -21px;
    padding-top: 15px;
    padding-bottom: 15px;
    ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    li{
      margin: 0 10px;
      &:not(:last-child):after {
        content: "|";
        margin-left: 10px;
      }
    }
    }
  }
}
.has_subtree .caret{
  border: solid rgb(255, 255, 255);
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: -7px;
}
#navlogo {
  padding-left: 15px;
  display: flex;
  align-items: center;
  #citynameimage img{
    height: 35px;
    margin-left: 8px;
  }
  #shieldimage img{
    width:45px;
  }
}
#sidethreenavwithicon{
  margin-bottom: 15px;
  a.btn:nth-child(3) {
    @media (max-width:599px){ // hide third button on small devices so it doesn't wrap.
      display: none;
    }
  }
}
#mobile-dropdown{
  font-size: 1.3em;
}
img.page-icon {
  max-width: 40px !important;
  float: left;
  margin-right: 10px;
}

#navbar > #navbar-large {
  @media (min-width: 1200px) {
    z-index: 4;
  }
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #fff;
}
.documentFirstHeading{
  margin-top:0;
}
.documentAuthor{
  font-weight: 700;
}
.documentModified{
  font-style: italic;
}
#sitealert{
  //margin-top: 15px;
  .slide-alert-message, .alert {
    &.alert-dismissible .close {
        right:0;
    }
    margin: 15px;
    padding: 15px;
  }
}
.pagination .page-item a .label{
  font-size: 1em;
  color:inherit;
  font-weight: normal;
}
body.template-summary_view {
  .tileItem {
    margin-bottom: 65px;
    display:flex;
  }
  .tileImage{
    float:left;
    margin-right: 15px;
    min-width: 128px;
    max-width: 128px;
    img {
      width:100%;
    }
  }
  h2.tileHeadline{
    margin-top:0;
  }
  .tileFooter{
    padding-top:10px;
  }
}
a.btn-primary .fa{
  color: white;
}
a.btn-primary:hover .fa {
  color: white;
}

.jumbotron{
  @extend .mt-4;
  @extend .p-5;
  @extend .bg-light;
  padding-right: 60px;
    padding-left: 60px;
    border-radius: 6px;
    background-color: #eee;
}
.image-right{
  float: right !important;
  margin: 0 0 15px 15px;
}
.image-left{
  float: left !important;
  margin: 15px 15px 0 0;
}
// autotoc from plone
.pat-autotoc {
  .autotoc-nav {
    float: none;
    border: 1px solid #DDD;
    padding: 0.5em 0;
    margin: 0 0 1em 1em;
    line-height: 1.75em;
    box-shadow: 2px 2px 3px #b4b5b4;
    a { display: block; }
    a:focus { outline-style: none; }
    .autotoc-level-1 { margin: 0 1em 0 1em; }
    .autotoc-level-2 { margin: 0 1em 0 2em; }
    .autotoc-level-3 { margin: 0 1em 0 1em; }
  }
  &.autotabs {
    .autotoc-nav {
      float: none;
      padding: 0;
      margin: 0 0 0.3em 0;
      border: 0;
      border-bottom: 1px solid #DDD;
      &:after {
        content: "";
        display: table;
        line-height: 0;
      }
      a {
        display: inline-block;
        margin: 0 0.5em -1px 0.5em;
        line-height: 1.5em;
        padding: 0.4em 0.8em;
        text-decoration: none;
        -webkit-border-radius: 4px 4px 0 0;
           -moz-border-radius: 4px 4px 0 0;
            -ie-border-radius: 4px 4px 0 0;
             -o-border-radius: 4px 4px 0 0;
                border-radius: 4px 4px 0 0;
        &.active {
          border: 1px solid #DDD;
          border-bottom-color: #FFF;
          color: #555555;
          cursor: default;
        }
        &.active:hover {
          background-color: transparent;
        }
        &:hover {
          background-color: #EEE;
          border-color: #EEE;
          border-bottom-color: #FFF;
        }
      }
    }
    .autotoc-section {
      display: none;
      &.active {
        display: block;
        legend {
          display: none;
        }
      }
    }
  }
}
.right-column {
  background: #e5e5e5;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0 0 10px 10px;
  float: right !important;
  width: 250px;
  height: auto;
  overflow: hidden;
}
pre.unPre {
  display: block;
  padding: 0;
  margin: 0;
  line-height: inherit;
  color: inherit;
  background: none;
  border: none;
  font: inherit;
}
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff !important;
}
.pagination li.active a{
  color:#fff !important;
}
/* FORM CSS */

.dropdown-menu-left{
  right:0;
  left:auto;
}
.dropdown-menu-center{
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
/* Classic picker styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */


 .picker {
	width:100%;
}

.picker__holder {
	position:absolute;
	background:#fff;
	border:1px solid #aaa;
	border-top-width:0;
	border-bottom-width:0;
	border-radius:0 0 5px 5px;
	box-sizing:border-box;
	min-width:176px;
	max-width:466px;
	max-height:0;
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	filter:alpha(opacity=0);
	-moz-opacity:0;
	opacity:0;
	-webkit-transform:translateY(-1em) perspective(600px) rotateX(10deg);
	transform:translateY(-1em) perspective(600px) rotateX(10deg);
	transition:-webkit-transform .15s ease-out,opacity .15s ease-out,max-height 0 .15s,border-width 0 .15s;
	transition:transform .15s ease-out,opacity .15s ease-out,max-height 0 .15s,border-width 0 .15s;
}

.picker__frame {
	padding:1px;
}

.picker__wrap {
	margin:-1px;
}

.picker--opened .picker__holder {
	max-height:25em;
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter:alpha(opacity=100);
	-moz-opacity:1;
	opacity:1;
	border-top-width:1px;
	border-bottom-width:1px;
	-webkit-transform:translateY(0) perspective(600px) rotateX(0);
	transform:translateY(0) perspective(600px) rotateX(0);
	transition:-webkit-transform .15s ease-out,opacity .15s ease-out,max-height 0s,border-width 0;
	transition:transform .15s ease-out,opacity .15s ease-out,max-height 0s,border-width 0;
	box-shadow:0 6px 18px 1px rgba(0,0,0,0.12);
}

.picker__box {
	padding:0 1em;
}

.picker__header {
	text-align:center;
	position:relative;
	margin-top:.75em;
}

.picker__month,.picker__year {
	font-weight:500;
	display:inline-block;
	margin-left:.25em;
	margin-right:.25em;
}

.picker__year {
	color:#999;
	font-size:.8em;
	font-style:italic;
}

.picker__select--month,.picker__select--year {
	border:1px solid #b7b7b7;
	height:2em;
	padding:.5em;
	margin-left:.25em;
	margin-right:.25em;
}

@media (min-width:24.5em) {
	.picker__select--month,.picker__select--year {
		margin-top:-.5em;
	}
}

.picker__select--month {
	width:35%;
}

.picker__select--year {
	width:22.5%;
}

.picker__select--month:focus,.picker__select--year:focus {
	border-color:#0089ec;
}

.picker__nav--prev,.picker__nav--next {
	position:absolute;
	padding:.5em 1.25em;
	width:1em;
	height:1em;
	box-sizing:content-box;
	top:-.25em;
}

@media (min-width:24.5em) {
	.picker__nav--prev,.picker__nav--next {
		top:-.33em;
	}
}

.picker__nav--prev {
	left:-1em;
	padding-right:1.25em;
}

@media (min-width:24.5em) {
	.picker__nav--prev {
		padding-right:1.5em;
	}
}

.picker__nav--next {
	right:-1em;
	padding-left:1.25em;
}

@media (min-width:24.5em) {
	.picker__nav--next {
		padding-left:1.5em;
	}
}

.picker__nav--prev:before,.picker__nav--next:before {
	content:" ";
	border-top:.5em solid transparent;
	border-bottom:.5em solid transparent;
	border-right:.75em solid #000;
	width:0;
	height:0;
	display:block;
	margin:0 auto;
}

.picker__nav--next:before {
	border-right:0;
	border-left:.75em solid #000;
}

.picker__nav--prev:hover,.picker__nav--next:hover {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
}

.picker__nav--disabled,.picker__nav--disabled:hover,.picker__nav--disabled:before,.picker__nav--disabled:before:hover {
	cursor:default;
	background:none;
	border-right-color:#f5f5f5;
	border-left-color:#f5f5f5;
}

.picker__table {
	text-align:center;
	border-collapse:collapse;
	border-spacing:0;
	table-layout:fixed;
	font-size:inherit;
	width:100%;
	margin-top:.75em;
	margin-bottom:.5em;
}

@media (min-height:33.875em) {
	.picker__table {
		margin-bottom:.75em;
	}
}

.picker__table td {
	margin:0;
	padding:0;
}

.picker__weekday {
	width:14.28571429%;
	font-size:.75em;
	padding-bottom:.25em;
	color:#999;
	font-weight:500;
}

@media (min-height:33.875em) {
	.picker__weekday {
		padding-bottom:.5em;
	}
}

.picker__day {
	padding:.3125em 0;
	font-weight:200;
	border:1px solid transparent;
}

.picker__day--today {
	position:relative;
}

.picker__day--today:before {
	content:" ";
	position:absolute;
	top:2px;
	right:2px;
	width:0;
	height:0;
	border-top:.5em solid #0059bc;
	border-left:.5em solid transparent;
}

.picker__day--disabled:before {
	border-top-color:#aaa;
}

.picker__day--outfocus {
	color:#ddd;
}

.picker__day--infocus:hover,.picker__day--outfocus:hover {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
}

.picker__day--highlighted {
	border-color:#0089ec;
}

.picker__day--highlighted:hover,.picker--focused .picker__day--highlighted {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
}

.picker__day--selected,.picker__day--selected:hover,.picker--focused .picker__day--selected {
	background:#0089ec;
	color:#fff;
}

.picker__day--disabled,.picker__day--disabled:hover,.picker--focused .picker__day--disabled {
	background:#f5f5f5;
	border-color:#f5f5f5;
	color:#ddd;
	cursor:default;
}

.picker__day--highlighted.picker__day--disabled,.picker__day--highlighted.picker__day--disabled:hover {
	background:#bbb;
}

.picker__footer {
	text-align:center;
}

.picker__button--today,.picker__button--clear,.picker__button--close {
	border:1px solid #fff;
	background:#fff;
	font-size:.8em;
	padding:.66em 0;
	font-weight:700;
	width:33%;
	display:inline-block;
	vertical-align:bottom;
}

.picker__button--today:hover,.picker__button--clear:hover,.picker__button--close:hover {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
	border-bottom-color:#b1dcfb;
}

.picker__button--today:focus,.picker__button--clear:focus,.picker__button--close:focus {
	background:#b1dcfb;
	border-color:#0089ec;
	outline:none;
}

.picker__button--today:before,.picker__button--clear:before,.picker__button--close:before {
	position:relative;
	display:inline-block;
	height:0;
}

.picker__button--today:before,.picker__button--clear:before {
	content:" ";
	margin-right:.45em;
}

.picker__button--today:before {
	top:-.05em;
	width:0;
	border-top:.66em solid #0059bc;
	border-left:.66em solid transparent;
}

.picker__button--clear:before {
	top:-.25em;
	width:.66em;
	border-top:3px solid #e20;
}

.picker__button--close:before {
	content:"\D7";
	top:-.1em;
	vertical-align:top;
	font-size:1.1em;
	margin-right:.35em;
	color:#777;
}

.picker__button--today[disabled],.picker__button--today[disabled]:hover {
	background:#f5f5f5;
	border-color:#f5f5f5;
	color:#ddd;
	cursor:default;
}

.picker__button--today[disabled]:before {
	border-top-color:#aaa;
}

.picker__list {
	list-style:none;
	padding:.75em 0 4.2em;
	margin:0;
}

.picker__list-item {
	border-bottom:1px solid #ddd;
	border-top:1px solid #ddd;
	margin-bottom:-1px;
	position:relative;
	background:#fff;
	padding:.75em 1.25em;
}

@media (min-height:46.75em) {
	.picker__list-item {
		padding:.5em 1em;
	}
}

.picker__list-item:hover {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
	border-color:#0089ec;
	z-index:10;
}

.picker__list-item--highlighted {
	border-color:#0089ec;
	z-index:10;
}

.picker__list-item--highlighted:hover,.picker--focused .picker__list-item--highlighted {
	cursor:pointer;
	color:#000;
	background:#b1dcfb;
}

.picker__list-item--selected,.picker__list-item--selected:hover,.picker--focused .picker__list-item--selected {
	background:#0089ec;
	color:#fff;
	z-index:10;
}

.picker__list-item--disabled,.picker__list-item--disabled:hover,.picker--focused .picker__list-item--disabled {
	background:#f5f5f5;
	border-color:#f5f5f5;
	color:#ddd;
	cursor:default;
	border-color:#ddd;
	z-index:auto;
}

.picker--time .picker__button--clear {
	display:block;
	width:80%;
	margin:1em auto 0;
	padding:1em 1.25em;
	background:none;
	border:0;
	font-weight:500;
	font-size:.67em;
	text-align:center;
	text-transform:uppercase;
	color:#666;
}

.picker--time .picker__button--clear:hover,.picker--time .picker__button--clear:focus {
	color:#000;
	background:#b1dcfb;
	background:#e20;
	border-color:#e20;
	cursor:pointer;
	color:#fff;
	outline:none;
}

.picker--time .picker__button--clear:before {
	top:-.25em;
	color:#666;
	font-size:1.25em;
	font-weight:700;
}

.picker--time .picker__button--clear:hover:before,.picker--time .picker__button--clear:focus:before {
	color:#fff;
	border-color:#fff;
}

.picker--time {
	min-width:256px;
	max-width:320px;
}

.picker--time .picker__holder {
	background:#f2f2f2;
}

@media (min-height:40.125em) {
	.picker--time .picker__holder {
		font-size:.875em;
	}
}

.picker--time .picker__box {
	padding:0;
	position:relative;
}

.pattern-pickadate-wrapper .btn {
	display:inline-block;
	margin-bottom:0;
	font-weight:400;
	text-align:center;
	vertical-align:middle;
	touch-action:manipulation;
	cursor:pointer;
	background-image:none;
	border:1px solid transparent;
	white-space:nowrap;
	padding:6px 12px;
	font-size:14px;
	line-height:1.42857143;
	border-radius:4px;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;
}

.pattern-pickadate-wrapper .btn:focus,.pattern-pickadate-wrapper .btn:active:focus,.pattern-pickadate-wrapper .btn.active:focus,.pattern-pickadate-wrapper .btn.focus,.pattern-pickadate-wrapper .btn:active.focus,.pattern-pickadate-wrapper .btn.active.focus {
	outline:thin dotted;
	outline:5px auto -webkit-focus-ring-color;
	outline-offset:-2px;
}

.pattern-pickadate-wrapper .btn:hover,.pattern-pickadate-wrapper .btn:focus,.pattern-pickadate-wrapper .btn.focus {
	color:#333;
	text-decoration:none;
}

.pattern-pickadate-wrapper .btn:active,.pattern-pickadate-wrapper .btn.active {
	outline:0;
	background-image:none;
	-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
	box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);
}

.pattern-pickadate-wrapper .btn.disabled,.pattern-pickadate-wrapper .btn[disabled],fieldset[disabled] .pattern-pickadate-wrapper .btn {
	cursor:not-allowed;
	opacity:.65;
	filter:alpha(opacity=65);
	-webkit-box-shadow:none;
	box-shadow:none;
}

a.pattern-pickadate-wrapper .btn.disabled,fieldset[disabled] a.pattern-pickadate-wrapper .btn {
	pointer-events:none;
}

.pattern-pickadate-wrapper .btn-info {
	color:#fff;
	background-color:#5bc0de;
	border-color:#46b8da;
}

.pattern-pickadate-wrapper .btn-info:focus,.pattern-pickadate-wrapper .btn-info.focus {
	color:#fff;
	background-color:#31b0d5;
	border-color:#1b6d85;
}

.pattern-pickadate-wrapper .btn-info:hover {
	color:#fff;
	background-color:#31b0d5;
	border-color:#269abc;
}

.pattern-pickadate-wrapper .btn-info:active,.pattern-pickadate-wrapper .btn-info.active,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-info {
	color:#fff;
	background-color:#31b0d5;
	border-color:#269abc;
}

.pattern-pickadate-wrapper .btn-info:active:hover,.pattern-pickadate-wrapper .btn-info.active:hover,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-info:hover,.pattern-pickadate-wrapper .btn-info:active:focus,.pattern-pickadate-wrapper .btn-info.active:focus,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-info:focus,.pattern-pickadate-wrapper .btn-info:active.focus,.pattern-pickadate-wrapper .btn-info.active.focus,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-info.focus {
	color:#fff;
	background-color:#269abc;
	border-color:#1b6d85;
}

.pattern-pickadate-wrapper .btn-info:active,.pattern-pickadate-wrapper .btn-info.active,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-info {
	background-image:none;
}

.pattern-pickadate-wrapper .btn-info.disabled:hover,.pattern-pickadate-wrapper .btn-info[disabled]:hover,fieldset[disabled] .pattern-pickadate-wrapper .btn-info:hover,.pattern-pickadate-wrapper .btn-info.disabled:focus,.pattern-pickadate-wrapper .btn-info[disabled]:focus,fieldset[disabled] .pattern-pickadate-wrapper .btn-info:focus,.pattern-pickadate-wrapper .btn-info.disabled.focus,.pattern-pickadate-wrapper .btn-info[disabled].focus,fieldset[disabled] .pattern-pickadate-wrapper .btn-info.focus {
	background-color:#5bc0de;
	border-color:#46b8da;
}

.pattern-pickadate-wrapper .btn-info .badge {
	color:#5bc0de;
	background-color:#fff;
}

.pattern-pickadate-wrapper .btn-danger {
	color:#fff;
	background-color:#d9534f;
	border-color:#d43f3a;
}

.pattern-pickadate-wrapper .btn-danger:focus,.pattern-pickadate-wrapper .btn-danger.focus {
	color:#fff;
	background-color:#c9302c;
	border-color:#761c19;
}

.pattern-pickadate-wrapper .btn-danger:hover {
	color:#fff;
	background-color:#c9302c;
	border-color:#ac2925;
}

.pattern-pickadate-wrapper .btn-danger:active,.pattern-pickadate-wrapper .btn-danger.active,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-danger {
	color:#fff;
	background-color:#c9302c;
	border-color:#ac2925;
}

.pattern-pickadate-wrapper .btn-danger:active:hover,.pattern-pickadate-wrapper .btn-danger.active:hover,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-danger:hover,.pattern-pickadate-wrapper .btn-danger:active:focus,.pattern-pickadate-wrapper .btn-danger.active:focus,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-danger:focus,.pattern-pickadate-wrapper .btn-danger:active.focus,.pattern-pickadate-wrapper .btn-danger.active.focus,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-danger.focus {
	color:#fff;
	background-color:#ac2925;
	border-color:#761c19;
}

.pattern-pickadate-wrapper .btn-danger:active,.pattern-pickadate-wrapper .btn-danger.active,.open>.dropdown-toggle.pattern-pickadate-wrapper .btn-danger {
	background-image:none;
}

.pattern-pickadate-wrapper .btn-danger.disabled:hover,.pattern-pickadate-wrapper .btn-danger[disabled]:hover,fieldset[disabled] .pattern-pickadate-wrapper .btn-danger:hover,.pattern-pickadate-wrapper .btn-danger.disabled:focus,.pattern-pickadate-wrapper .btn-danger[disabled]:focus,fieldset[disabled] .pattern-pickadate-wrapper .btn-danger:focus,.pattern-pickadate-wrapper .btn-danger.disabled.focus,.pattern-pickadate-wrapper .btn-danger[disabled].focus,fieldset[disabled] .pattern-pickadate-wrapper .btn-danger.focus {
	background-color:#d9534f;
	border-color:#d43f3a;
}

.pattern-pickadate-wrapper .btn-danger .badge {
	color:#d9534f;
	background-color:#fff;
}

.pattern-pickadate-wrapper {
	position:relative;
	margin-bottom:.5em;
}

.pattern-pickadate-wrapper .btn {
	vertical-align:top;
	margin:.2em;
}

.pattern-pickadate-wrapper .picker {
	display:none;
}

.pattern-pickadate-wrapper .picker.picker--opened {
	display:block;
}

.pattern-pickadate-wrapper .pattern-pickadate-date-wrapper {
	display:inline-block;
	vertical-align:middle;
}

.pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__input {
	width:300px;
	margin:.2em;
	text-align:center;
	cursor:context-menu;
}

.pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__holder {
	width:300px;
	font-size:12px;
  margin-top:-2px;
  z-index: 1;
}

.pattern-pickadate-wrapper .pattern-pickadate-separator {
	display:inline-block;
	vertical-align:middle;
	margin:0 .2em;
}

.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
	display:inline-block;
	vertical-align:middle;
}

.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
	width:180px;
	margin:.2em;
	text-align:center;
	cursor:context-menu;
}

.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__holder {
	width:180px;
	font-size:11px;
  margin-top:-2px;
  overflow:auto;
}

.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__list-item {
	text-align:center;
}

.pattern-pickadate-wrapper .pattern-pickadate-timezone-wrapper {
	display:inline-block;
	vertical-align:middle;
}

.pattern-pickadate-wrapper .pattern-pickadate-now {
	display:inline-block;
}

.pattern-pickadate-wrapper .pattern-pickadate-clear {
	display:inline-block;
}

.pattern-pickadate-wrapper .picker__select--month,.pattern-pickadate-wrapper .picker__select--year {
	height:3em;
}

.pattern-pickadate-wrapper .picker__footer {
	display:none;
}
 /* ==========================================================================
   $BASE-DATE-PICKER
   ========================================================================== */
/**
 * The picker box.
 */
.picker__box {
  padding: 0 1em;
}
/**
 * The header containing the month and year stuff.
 */
.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em;
}
/**
 * The month and year labels.
 */
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
}
.picker__year {
  color: #999999;
  font-size: .8em;
  font-style: italic;
}
/**
 * The month and year selectors.
 */
.picker__select--month,
.picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  padding: .5em;
  margin-left: .25em;
  margin-right: .25em;
}
@media (min-width: 24.5em) {
  .picker__select--month,
  .picker__select--year {
    margin-top: -0.5em;
  }
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 22.5%;
}
.picker__select--month:focus,
.picker__select--year:focus {
  border-color: #0089ec;
}
/**
 * The month navigation buttons.
 */
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: .5em 1.25em;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  top: -0.25em;
}
@media (min-width: 24.5em) {
  .picker__nav--prev,
  .picker__nav--next {
    top: -0.33em;
  }
}
.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em;
}
@media (min-width: 24.5em) {
  .picker__nav--prev {
    padding-right: 1.5em;
  }
}
.picker__nav--next {
  right: -1em;
  padding-left: 1.25em;
}
@media (min-width: 24.5em) {
  .picker__nav--next {
    padding-left: 1.5em;
  }
}
.picker__nav--prev:before,
.picker__nav--next:before {
  content: " ";
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: 0.75em solid #000000;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto;
}
.picker__nav--next:before {
  border-right: 0;
  border-left: 0.75em solid #000000;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #f5f5f5;
  border-left-color: #f5f5f5;
}
/**
 * The calendar table of dates
 */
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}
@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em;
  }
}
.picker__table td {
  margin: 0;
  padding: 0;
}
/**
 * The weekday labels
 */
.picker__weekday {
  width: 14.285714286%;
  font-size: .75em;
  padding-bottom: .25em;
  color: #999999;
  font-weight: 500;
  /* Increase the spacing a tad */
}
@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}
/**
 * The days on the calendar
 */
.picker__day {
  padding: .3125em 0;
  font-weight: 200;
  border: 1px solid transparent;
}
.picker__day--today {
  position: relative;
}
.picker__day--today:before {
  content: " ";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 0.5em solid #0059bc;
  border-left: .5em solid transparent;
}
.picker__day--disabled:before {
  border-top-color: #aaaaaa;
}
.picker__day--outfocus {
  color: #dddddd;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
}
.picker__day--highlighted {
  border-color: #0089ec;
}
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background: #0089ec;
  color: #ffffff;
}
.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}
.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: #bbbbbb;
}
/**
 * The footer containing the "today", "clear", and "close" buttons.
 */
.picker__footer {
  text-align: center;
}
.picker__button--today,
.picker__button--clear,
.picker__button--close {
  border: 1px solid #ffffff;
  background: #ffffff;
  font-size: .8em;
  padding: .66em 0;
  font-weight: bold;
  width: 33%;
  display: inline-block;
  vertical-align: bottom;
}
.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
  cursor: pointer;
  color: #000000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}
.picker__button--today:focus,
.picker__button--clear:focus,
.picker__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none;
}
.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
  position: relative;
  display: inline-block;
  height: 0;
}
.picker__button--today:before,
.picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}
.picker__button--today:before {
  top: -0.05em;
  width: 0;
  border-top: 0.66em solid #0059bc;
  border-left: .66em solid transparent;
}
.picker__button--clear:before {
  top: -0.25em;
  width: .66em;
  border-top: 3px solid #ee2200;
}
.picker__button--close:before {
  content: "\D7";
  top: -0.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: .35em;
  color: #777777;
}
.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #dddddd;
  cursor: default;
}
.picker__button--today[disabled]:before {
  border-top-color: #aaaaaa;
}

/* ==========================================================================
   $CLASSIC-DATE-PICKER
   ========================================================================== */


/* tables from barceloneta.css */
table {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 400;
  color: #696969;
}
th {
  text-align: left;
  color: #4d4d4d;
}
.listing {
  width: 100%;
  margin-bottom: 20px;
}
.listing > thead > tr > th,
.listing > tbody > tr > th,
.listing > tfoot > tr > th,
.listing > thead > tr > td,
.listing > tbody > tr > td,
.listing > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0 solid #e5e5e5;
  border-collapse: collapse;
}
.listing > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #e5e5e5;
}
.listing > caption + thead > tr:first-child > th,
.listing > colgroup + thead > tr:first-child > th,
.listing > thead:first-child > tr:first-child > th,
.listing > caption + thead > tr:first-child > td,
.listing > colgroup + thead > tr:first-child > td,
.listing > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.listing > tbody + tbody {
  border-top: 2px solid #e5e5e5;
}
.listing .listing {
  background-color: #fafafa;
}
.invisible-grid {
  width: 100%;
  margin-bottom: 20px;
  border: 0;
  background: transparent;
}
.invisible-grid > thead > tr > th,
.invisible-grid > tbody > tr > th,
.invisible-grid > tfoot > tr > th,
.invisible-grid > thead > tr > td,
.invisible-grid > tbody > tr > td,
.invisible-grid > tfoot > tr > td {
  border: 0;
}
.listing > tbody > tr:nth-child(odd) > td,
.listing > tbody > tr:nth-child(odd) > th {
  background-color: #f6f6f6;
}
@media screen and (max-width: 767px) {
  .plone-has-table {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
  .plone-has-table::after {
    content: "⟷";
    position: fixed;
    color: #cccccc;
    margin-top: -3px;
  }
  .plone-has-table > .listing {
    margin-bottom: 0;
  }
  .plone-has-table > .listing > thead > tr > th,
  .plone-has-table > .listing > tbody > tr > th,
  .plone-has-table > .listing > tfoot > tr > th,
  .plone-has-table > .listing > thead > tr > td,
  .plone-has-table > .listing > tbody > tr > td,
  .plone-has-table > .listing > tfoot > tr > td {
    white-space: nowrap;
  }
}
table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #e5e5e5;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}
table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #e5e5e5;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
table > tbody + tbody {
  border-top: 2px solid #e5e5e5;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 1px solid #e5e5e5;
}
table > thead > tr > th,
table > thead > tr > td {
  border-bottom: 1px solid #e5e5e5;
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}

  /* TINYMCE FIXES */
  body#tinymce { // remove flex display for tinymce WYSIWYG area
    display: unset;
  }


  /* GOOGLE CUSTOM SEARCH RESULTS*/
  div.csesearch {
    #cse-search-form{
      margin-bottom: 10px;
    }
    .form-group {
        display: inline-flex;
        align-items: center;
        span {
          margin-top: 7px;
          margin-left: 5px;
          margin-right: 5px;
      }
    }
    div.results{
      display:inline-block;
      margin-bottom:1em;
      div.row{
        margin-bottom:1em;
      }
    }

    div.listingbar{
      clear:both;
      font-size:1em;
    }
    ul.pagination{
      list-style-type:none;
      padding-left:0;
      li{
        display:inline-block;
        font-size:1.3rem;
      }
    }
    span.results-url {
      padding-top: .3em;
      display: inherit;
      color:#008000 ;
    }
}
// FONTAWESOME SIZING

.fa2{
	font-size: 2rem;
}
.fa3{
	font-size: 3rem;
}
.fa4{
	font-size: 4rem;
}
.fa5{
	font-size: 5rem
}
// ------------------------------------------ //
// footer
// ------------------------------------------ //

.cabq-footer {
  .social {
    display: block;
    margin-bottom: 0.75rem;
    padding-left: 0;
    font-size: 1em;
    line-height: 1.875rem;
    .fa{
      vertical-align: middle;
      color: $cabq-blue1;
    }
    span{
	    margin-left:10px;
    }
  }
  .copyright {
    padding: 5.125rem 0;
    text-align: center;
  }
}
#footer {
  display: flex;
  flex-direction: column;
  padding: 1em;
  @media screen and (max-width: 768px){ 
    padding-right: 30px;
    padding-left: 30px;
  }
}
// IMAGE NEWS CONTAINER FORMATTING
#section-leadimage {
  width: 30%;
  float: right;
  margin: 0 0 6px 6px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.17);
  overflow: hidden;
  @media (max-width: 767px) {
    display: none !important;
 }
}
.newsImageContainer {
  a{
    display: table;
    img {
      display: block;
    }
  }
  figcaption {
    font-size:1em;
    padding: 10px;
    display: table-caption;
    caption-side: bottom;
    background: #fcfcfd;
  }
}
.modal-image img {
  width: 100%;
}
// FD8485 - margin for anchors to accomodate sticky header
@media screen and (min-width: 1200px){
 #content-core .pat-autotoc{
   display: initial;
  h1,h2,h3{
    padding-top: 130px;
    margin-top: -130px;
    }
  }
}
// if I set to unset/static the select existing image popup works, if I set to relative, the tinymce modals work. 
body.plone-modal-open{
  position:static;
}
div#offsite-warning-dialog {
  display: none;
  z-index: 9999;
  position: fixed;
  top: 0;
  width: 100%;

  h4.modal-title {
    text-align: center;
    text-transform: uppercase;
    color: #004795;
    font-size:1.5em;
  }

  .modal-body p {
    font-size: 1em;
  }

  button.btn-modal:hover {
    cursor: pointer;
  }

  .modal-header .close {
    font-size: 3.5em;
  }
  #offsite-warning-link-url {
    word-break: break-all;
  }
}
div.gallery-tile {
  &> .row {
    margin: 0;
  }
  h1 {
    font-family: 'Raleway';
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-size: 50px;
  }
  hr {
    border: 0;
    height: 2px;
    background-color: hsl(120,0.7%,70.8%);
  }
  &.normal{
    .thumbnail{
      border: none;
      justify-content: center;
      a{
        border: 5px solid #f3f1ee;
      }
    }
  }
  .thumbnail {
    margin-bottom:6px;
    display:flex;
    flex-wrap: wrap;
    border: 5px solid #f3f1ee;
    padding: 0;
  }
  .blur {
    transform: scale(0.9);
    opacity: 0.6;
  	-webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }
}

.gallery-image-link:hover {
    cursor: pointer;
}

#gallery-tile-modal{
  .modal-header{
    display:flex;
    justify-content: space-between;
    .modal-title{
      margin-right: auto;
    }
    .close{
      margin-left: auto;
    }
  }
  @media (min-width: 768px){
    .modal-dialog { // make modal dialog wider for desktop viewports
        width: 812px;
        margin: 30px auto;
    }
  }
}
#news-item-date{
  font-weight: bold;
}
.tab-content {
  border: 1px solid #ccc;
  padding: 1em;
  border-radius: 1px 1px 4px 4px;
  margin-top: -1px;
}
.nav-tabs li a{
  border: 1px solid #ddd;
}

a[role="tab"][aria-selected="true"] {
  border-top-width:5px !important;
  border-top-color:$cabq-secondary !important;
  padding-top: 6px;
}
.nav-pills {
  a[role="tab"]{
    border: 1px solid $cabq-grey2;
    &:focus-visible{
      outline: -webkit-focus-ring-color auto 1px !important;
    }
  }
  a[role="tab"][aria-selected="true"]{
    border-top: 5px solid $cabq-grey1 !important;
    padding-top: 5px;
  }
  a.btn-primary[role="tab"][aria-selected="true"]{
    border-top: 5px solid $cabq-grey2 !important;
  }
}

// btn-navcall color for header
.btn-secondary { 
  color: $cabq-black;
  background-color: $btn-secondary-bg; 
  border-color: $btn-secondary-border; 
  text-transform: uppercase;
  &:link,&:visited,&:hover{
    color: $cabq-black !important;
  }
} 
 
.btn-secondary:hover, 
.btn-secondary:focus, 
.btn-secondary:active, 
.btn-secondary.active, 
.open .dropdown-toggle.btn-secondary { 
  color: $cabq-black; 
  background-color: lighten($btn-secondary-bg, 20%); 
  border-color: $btn-secondary-border; 
} 
 
.btn-secondary:active, 
.btn-secondary.active, 
.open .dropdown-toggle.btn-secondary { 
  background-image: none; 
} 
 
.btn-secondary.disabled, 
.btn-secondary[disabled], 
fieldset[disabled] .btn-secondary, 
.btn-secondary.disabled:hover, 
.btn-secondary[disabled]:hover, 
fieldset[disabled] .btn-secondary:hover, 
.btn-secondary.disabled:focus, 
.btn-secondary[disabled]:focus, 
fieldset[disabled] .btn-secondary:focus, 
.btn-secondary.disabled:active, 
.btn-secondary[disabled]:active, 
fieldset[disabled] .btn-secondary:active, 
.btn-secondary.disabled.active, 
.btn-secondary[disabled].active, 
fieldset[disabled] .btn-secondary.active { 
  background-color: $btn-secondary-bg; 
  border-color: $btn-secondary-border; 
} 
 
.btn-secondary .badge { 
  color: $btn-secondary-color; 
  background-color: $btn-secondary-bg; 
}
.btn.btn-outline-secondary{
  color: $cabq-black !important;
  border: 1px solid $cabq-dark-blue !important;
  svg{
    color: $cabq-dark-blue;
  }
}
// btn-navcall color for header

.btn-default { 
  color: $cabq-black; 
  background-color: $btn-default-bg; 
  border-color: $btn-default-border; 
  &:link,&:visited,&:hover{
    color: $cabq-black !important;
  }
} 
 
.btn-default:hover, 
.btn-default:focus, 
.btn-default:active, 
.btn-default.active, 
.open .dropdown-toggle.btn-default { 
  color: $btn-default-color; 
  background-color: darken($btn-default-bg,10%); 
  border-color: $btn-default-border; 
} 
 
.btn-default:active, 
.btn-default.active, 
.open .dropdown-toggle.btn-default { 
  background-image: none; 
} 
 
.btn-default.disabled, 
.btn-default[disabled], 
fieldset[disabled] .btn-default, 
.btn-default.disabled:hover, 
.btn-default[disabled]:hover, 
fieldset[disabled] .btn-default:hover, 
.btn-default.disabled:focus, 
.btn-default[disabled]:focus, 
fieldset[disabled] .btn-default:focus, 
.btn-default.disabled:active, 
.btn-default[disabled]:active, 
fieldset[disabled] .btn-default:active, 
.btn-default.disabled.active, 
.btn-default[disabled].active, 
fieldset[disabled] .btn-default.active { 
  background-color: $btn-default-bg; 
  border-color: $btn-default-border; 
} 
 
.btn-default .badge { 
  color: $btn-default-color; 
  background-color: $btn-default-bg; 
}

// btn-caret color for header
.btn-caret { 
  color: $cabq-black; 
  background-color: $btn-caret-bg; 
  border-color: $btn-caret-border; 
  text-transform: uppercase;
  padding: 0 3px;
  line-height: 1;
  margin-top: -4px;
  margin-left: 5px;
  &:link{
    color: $cabq-black !important;
  }
  @media screen and (max-width:768px) {
      float: left;
      margin-top: 2px;
      margin-right: 6px;
  }
} 
 
.btn-caret:hover, 
.btn-caret:focus, 
.btn-caret:active, 
.btn-caret.active, 
.open .dropdown-toggle.btn-caret { 
  color: $cabq-black; 
  background-color: lighten($btn-caret-bg, 20%); 
  border-color: $btn-caret-border; 
} 
 
.btn-caret:active, 
.btn-caret.active, 
.open .dropdown-toggle.btn-caret { 
  background-image: none; 
} 
 
.btn-caret.disabled, 
.btn-caret[disabled], 
fieldset[disabled] .btn-caret, 
.btn-caret.disabled:hover, 
.btn-caret[disabled]:hover, 
fieldset[disabled] .btn-caret:hover, 
.btn-caret.disabled:focus, 
.btn-caret[disabled]:focus, 
fieldset[disabled] .btn-caret:focus, 
.btn-caret.disabled:active, 
.btn-caret[disabled]:active, 
fieldset[disabled] .btn-caret:active, 
.btn-caret.disabled.active, 
.btn-caret[disabled].active, 
fieldset[disabled] .btn-caret.active { 
  background-color: $btn-caret-bg; 
  border-color: $btn-caret-border; 
} 
 
.btn-caret .badge { 
  color: $btn-caret-color; 
  background-color: $btn-caret-bg; 
}
a.siteSearchButton svg {
  color: $cabq-dark-blue;
  font-size: 1.2em;
}
.menu-item.translatesearch {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  &>a{
    font-size: 1.1em;
  }
  input#nav-mini-query {
    width: 11em;
    font-size: 1em;
    @media (max-width: 599px){
      width: 7em;
    }
  }
}
li#mobilelogo {
  background-color: #fff;
  margin-left: -20px;
  padding: 12px 0 12px 0 !important;
  border-bottom: 1px solid $cabq-grey2;
}

#languages .modal-body{
  overflow: scroll;
}
div#sidebar-left {
  margin-bottom: 15px;
  @media (max-width:768px){
    margin-left: unset;
  }
}
.interior{
  .pageContent{
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 15px;
    height: fit-content;
  }
  aside{
    &#portal-column-one, &#portal-column-two {
      background-color: #fff;
      padding: 15px;
      border-radius: 4px;
    }
  }
  #rightnav{
    display:none;
  }
}

@media (max-width:769px){
  body.interior .mosaic-grid-row .mosaic-grid-cell:nth-child(odd) .mosaic-cabq\.sectionhighlight-tile {
    margin-left: -15px;
    margin-right: -15px;
  }
  body.interior .mosaic-grid-row .mosaic-grid-cell:nth-child(even) .mosaic-cabq\.sectionhighlight-tile {
    margin-right: -15px;
    margin-left: -15px;
  }
}
// PAGE EDITING CHANGES
[class*="edit"], .template-folder_contents {
 /* &.interior #main #mainleft {
    background-color: #fff;
  }
  .pageContent {
    left: 0;
    width: 100%;
  }*/
  .pat-autotoc.autotabs .autotoc-nav {
    border: 1px solid $cabq-grey3;
  }
  .formHelp {
    font-weight: 300;
    display: block;
  }
  .mosaic-tile-buttons .mosaic-btn-settings::before {
    color: #fff;
  }
  .pat-autotoc.autotabs, .autotabs {
    background: #ffffff;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    .autotoc-nav .active{
      font-weight: $font-weight-bold;
      border: 1px solid;
    }
  }
}

body[class^="template-"], body[class*=" template-"], body.portaltype-easyform.subsection-fields{
  .outer-wrapper {
    width: 80%;
  }
  #portal-column-content {
    width: 100% !important;
  }
}
// style toolbar links that do not have the .nav-link class (delete, rename)
body.plone-toolbar-left #edit-zone li a {
@extend .nav-link;
color: var(--plone-toolbar-text-color);
}

#searchModal, #translatePopup {
  button.close {
  background-color: $cabq-primary;
  color: #fff;
  opacity: 1;
  padding: 5px 15px;
  margin-top: -16px;
  margin-right: -16px;
  font-size: 3em;
  }
  .modal-header{
    border-bottom: none;
    display:flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .modal-footer{
    border-top: none;
  }
  .translate-languages{
    display:flex;
    flex-wrap:wrap;
  }
  div#modaltranslatedisclaimerlink {
    text-align: center;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bold;
  }
}

#translatePopup button.close {
    font-size: 2.5em;
    padding: 4px 14px 9px 14px;
}

.modal-backdrop {
  background-color: $cabq-primary;
} 
select#form-widgets-ICollection-customViewFields-from,select#form-widgets-ICollection-customViewFields-to {
  height: 100%;
}
a.dropdown-toggle:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}
@media (min-width: 768px){
  .navbar-nav {
      margin: 0 -5px 0 0 !important;
  }
}
.navbar-nav li.has_subtree a.nav-link {
  padding-right: 0; //override plone _sitenav.scss
}
/* subnavigation folder items treatment */
.navTreeLevel1>.navTreeFolderish{
  &>a.navTreeFolderish:before {
    margin-left: -2px;
    border: solid $cabq-primary;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-top: -7px;
    width: 10px;
    height: 10px;
    margin-top: 5px;
    margin-right: 8px;
  }  
  &>a.navTreeCurrentNode.navTreeFolderish:before {
    margin-left: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -7px;
    margin-top: 3px;
    margin-right: 8px;
  }
  &>a:not(.navTreeCurrentNode).navTreeFolderish:hover::before
  {
    border: solid #fff;
    border-width: 0 2px 2px 0;
  }
}
.navTreeLevel1 > .navTreeItem > a:not(.navTreeFolderish) {
  margin-left: 1em; //have non folderish items line up w/ folder item text
}
body.subsection-icons-white-icons #content {
  .summary .thumb-thumb, figure.image-product, .dataTable .title{ // white icon background via Ann email 2/13/2024
    background-color: #d3d3d3;
  }
}

body[class^='template-'], body[class*=' template-'] article#portal-column-content {
  @extend .col;
}
.actionmenu {
  .btn.action {
    color: $cabq-dark-blue !important;
    border-color: transparent !important
  }
  .dropdown-toggle{
    padding: 1em !important;
  }
}
body.homepage #sidebar-right .portlet:not(.portletQuickNavPortlet) {
  display: none;
}
.homepage div#sidebar-right {
  display: inherit;
  height: 0;
}

body:not(.portaltype-easyform, .template-folder_contents) #contentview-edit .nav-link .toolbar-label:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-top: .25em;
  margin-right: .5em;
  flex-shrink: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
body:not(.plone-toolbar-left-expanded, .portaltype-easyform, .template-folder_contents) #contentview-edit .nav-link:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-top: .25em;
    margin-right: .5em;
    flex-shrink: 0;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

.well{ // convert old BS3 well to BS5 .card
  @extend .card;
  @extend .card-body;
  background-color: $cabq-grey3;
  margin-bottom: 15px;
}
.highlight-inline, .highlight-paragraph {
  background: #FFFE14;
}

// Map Bootstrap 3 embed classes to Bootstrap 5
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  &.embed-responsive-16by9::before {
    padding-top: 56.25%; // 16:9 aspect ratio
  }

  &.embed-responsive-4by3::before {
    padding-top: 75%; // 4:3 aspect ratio
  }

  & > iframe,
  & > embed,
  & > video,
  & > object {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Ensure that the Bootstrap 5 embed utility classes are applied
.ratio {
  position: relative;
  width: 100%;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  &.ratio-16x9::before {
    padding-top: 56.25%; // 16:9 aspect ratio
  }

  &.ratio-4x3::before {
    padding-top: 75%; // 4:3 aspect ratio
  }

  & > iframe,
  & > embed,
  & > video,
  & > object {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
}

// Map Bootstrap 3 push and pull classes to Bootstrap 5 order utilities

// Mixin to generate push and pull classes
@mixin generate-order-classes($prefix, $direction, $start, $end) {
  @for $i from $start through $end {
    .#{$prefix}-#{$direction}-#{$i} {
      order: if($direction == 'push', $i, -$i);
    }
  }
}

// Generate classes for each breakpoint
@include generate-order-classes('col-xs', 'push', 1, 12);
@include generate-order-classes('col-xs', 'pull', 1, 12);

@include generate-order-classes('col-sm', 'push', 1, 12);
@include generate-order-classes('col-sm', 'pull', 1, 12);

@include generate-order-classes('col-md', 'push', 1, 12);
@include generate-order-classes('col-md', 'pull', 1, 12);

@include generate-order-classes('col-lg', 'push', 1, 12);
@include generate-order-classes('col-lg', 'pull', 1, 12);

.eventImageandContact .card {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
}
.btn-block {
  display: block;
  width: 100%;
}
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border .2s ease-in-out;
  -o-transition: border .2s ease-in-out;
  transition: border .2s ease-in-out;
  &>img, &a>img {
    margin-right: auto;
    margin-left: auto;
  }
}
// cabq-tabs - bring it BS 5 tab styling
.cabq-tabs{
  .nav-tabs{
    li{
      &:not(.active) a{
        padding-top: 12px;
      }
      @extend .nav-item;
      &.active{
        border-bottom: 1px solid white;
        margin-bottom: -1px;
      }
      a{
        @extend .nav-link;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
      }
    }
  }
}