$cabq-tan: #474135;
$cabq-tan-light: lighten($cabq-tan, 70%);
$cabq-tan-light:    #ebddc6;
$cabq-grey3:        #f3f3f3;
$cabq-grey2:        #9B9B9B;
$cabq-grey1:        #4a4a4a;
$cabq-black:        #212427; // 2024 black
$cabq-dark-red1:    #881A2C;
$cabq-dark-red2:    #A22035;
$cabq-red:          #F50F30;
$cabq-orange-dark:  #DC751A;
$cabq-orange1:      #F59F1F; // 2024 orange
$cabq-orange2:      #FFC76B;
$cabq-yellow1:      #FFD700;
$cabq-yellow2:      #FEE99C;
$cabq-yellow3:      #F9F2DA;
$cabq-yellow-bg:    #FBF9F2;
$cabq-dark-blue:    #01636A; //2024 turquois
$cabq-blue1:        #00CAD4;
$white:             #fff;
$dark-grey: $cabq-grey1;
// old theme colors
$cabq-blue:         #0081d1;
$cabq-medium-blue:  #00619e;
// @cabq-dark-blue:    #002238;
$cabq-dark-blue-20: lighten($cabq-dark-blue, 80%);
$cabq-dark-blue-10: lighten($cabq-dark-blue, 90%);
$cabq-dark-blue-05: lighten($cabq-dark-blue, 95%);

$cabq-primary:     $cabq-dark-blue;
$cabq-secondary:   $cabq-orange1;
$cabq-link: #017a7d; //color requested by CABQ for WCAG and sourrounding text
$table-border: #b4b5b4;
$table-odd-even: #f6f6f6;
$input-hover: $cabq-grey3;
$form-error: #c4183c;
$othernav-background: $cabq-tan-light;
$othernav-background-no-transparent: $cabq-tan-light;

// secondary button
$btn-secondary-color: $cabq-black;
$btn-secondary-bg: $cabq-orange1; 
$btn-secondary-border: $cabq-orange1;

// default button
$btn-default-color: $cabq-black;
$btn-default-bg: lighten($cabq-grey2,30%); 
$btn-default-border: $cabq-grey1;

// carat button
$btn-caret-color: #fff;
$btn-caret-bg: $cabq-dark-blue; 
$btn-caret-border: $cabq-dark-blue;

$primary: $cabq-primary;
$secondary: $cabq-secondary;
$tertiary: #1B470E;

$enable-gradients: false;
$enable-negative-margins: true;
