// Theme import structure is based on Bootstrap documentation
// https://getbootstrap.com/docs/5.3/customize/sass/#importing

// Barceloneta and Bootstrap import are using --load-path=node_modules

// Roboto is not included in this template and therefore we disable the setup here.
$enable-roboto-webfont: false !default;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "variables";

// 3. Include remainder of required Barceloneta and Bootstrap stylesheets
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.dark.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.properties";
@import "@plone/plonetheme-barceloneta-base/scss/variables.barceloneta";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
@import "maps";

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.portlets.plone";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.font.plone";
@import "@plone/plonetheme-barceloneta-base/scss/root_variables";

// 6. Bootstrap base parts as needed
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid"; 
@import "bootstrap/scss/helpers";

// 7. Bootstrap components
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// 8. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 9. Barceloneta base
@import "@plone/plonetheme-barceloneta-base/scss/alerts";
@import "@plone/plonetheme-barceloneta-base/scss/forms";
@import "@plone/plonetheme-barceloneta-base/scss/controlpanels";
@import "@plone/plonetheme-barceloneta-base/scss/login";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";

// pull out barceloneta grid to use bootstrap 5 grid classes
//@import "@plone/plonetheme-barceloneta-base/scss/grid";
@import "@plone/plonetheme-barceloneta-base/scss/content_base";
@import "@plone/plonetheme-barceloneta-base/scss/content_tables";

// Barceloneta full
@import "@plone/plonetheme-barceloneta-base/scss/cards";
@import "@plone/plonetheme-barceloneta-base/scss/scaffolding";
@import "@plone/plonetheme-barceloneta-base/scss/icons";
@import "@plone/plonetheme-barceloneta-base/scss/header";
// comment out the Barceloneta sitenav because it messes with our navigation
// @import "@plone/plonetheme-barceloneta-base/scss/sitenav";
@import "@plone/plonetheme-barceloneta-base/scss/breadcrumbs";
@import "@plone/plonetheme-barceloneta-base/scss/content";
@import "@plone/plonetheme-barceloneta-base/scss/comments";
@import "@plone/plonetheme-barceloneta-base/scss/portlets";
@import "@plone/plonetheme-barceloneta-base/scss/footer";

@import "@plone/plonetheme-barceloneta-base/scss/print";

// @import "@plone/plonetheme-barceloneta-base/scss/roboto-webfont";


// 9. Add additional custom code here
body.mosaic-layout-customized, body.mosaic-enabled {
    #call-to-action .aspect-ratio-box-inside {
      max-width: 100%;
      max-height: 100%;
      a {
        color: white !important;
      }
    }
    &.col-one.col-two.col-content #portal-column-content, &.col-two.col-content #portal-column-content, &.col-one.col-content #portal-column-content{
      width:100%;
    }
    
  }

  body.template-edit, body.template-object_rename{
    &.col-one.col-two.col-content #portal-column-content, &.col-two.col-content #portal-column-content, &.col-one.col-content #portal-column-content{
      width:100% !important;
    }
  }

  // fix the folder content z-index issue
  body:not(.backend) .outer-wrapper {
    position: relative;
    z-index: 1;
  }
  
  div.riform {
    z-index: 99;
  }
  // FD9683 remove upload button from folder contents view
  
  .template-folder_contents .pat-structure {
    a#btn-upload {
      display: none;
    }
    a#btn-rearrange.btn {
      border-right: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 15px;
    }
  }
@import "custom";
